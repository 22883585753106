import React, { useState,useEffect,} from 'react';
import Photo from './Common/Component/Photo';
import Timeline from './Common/Component/Photo/Timeline';
import Metro from './Common/Component/Metro';
import Home from './Screen/Home';
import Company from './Screen/Company/BizPlan';
import Service from './Screen/Service';
import BizMember from './Screen/Company/BizMember';
import BizSkill from './Screen/Company/BizSkill';
import Product from './Screen/Product/WindowsApp';
import ProductMobile from './Screen/Product/MobileApp';
import Community from './Screen/Community';
import Help from './Screen/Help';
import Portfolio from './Screen/WorkRoom/Portfolio';
import WorkCalendar from './Screen/WorkRoom/MySchedule';
import WorkRoom from './Screen/WorkRoom';
import Developer from './Screen/WorkRoom/MyWork/Developer';
import Download from './Screen/Download/WindowsApp';
import DownloadMobile from './Screen/Download/MobileApp';
import Join from './Common/Component/ChatRoom/Join/Join';
import Chat from './Common/Component/ChatRoom/Chat/Chat';
import Board from './Common/Component/Board/index';
import MemberJoin from './Common/Component/LoginBox/Join';
import FindMemberInfo from './Common/Component/LoginBox/FindMemberInfo';
import MyInfoUpdate from './Common/Component/LoginBox/MyInfoUpdate';
import MyPage from './Common/Component/MyPage';
import Remote from './Common/Component/RemotePage';
import ReactDOM from 'react-dom';
import footerimg from './image/design/footerimg.png';
import { Route } from 'react-router-dom';
import Uploader from './Screen/Uploader';
import Study from './Screen/Study';
import VideoChat from './Common/Component/VideoChat';
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid'; 
import TextContainer from './Common/Component/ChatRoom/TextContainer/TextContainer';
import Messages from './Common/Component/ChatRoom/Messages/Messages';
import InfoBar from './Common/Component/ChatRoom/InfoBar/InfoBar';
import Input from './Common/Component/ChatRoom/Input/Input';
import io from "socket.io-client";


import './Common/Component/ChatRoom/Chat/Chat.css';

import './App.css';


function App() {
  let socket = io('https://edu.antsnest.co.kr');
  return (
    <div className="App">
      <header className="App-header">
        <Metro />
      </header>
      <div id="Contents" className="MasterPanel">
        <Route path="/" exact component={Home} key="Home" />
        <Route path="/Uploader" exact component={Uploader} key="Home" />
        <Route path="/Company" exact component={Company} key="Company" />
        <Route path="/Service" exact component={Service} key="Service" />
        <Route path="/BizSkill" exact component={BizSkill} key="BizSkill" />
        <Route path="/BizMember" exact component={BizMember} key="BizMember" />
        <Route path="/Product" exact component={Product} key="Product" />
        <Route path="/ProductMobile" exact component={ProductMobile} key="ProductMobile" />
        <Route path="/Community" exact component={Community} key="Community" />
        <Route path="/Help" exact component={Help} key="Help" />
        <Route path="/WorkRoom" exact component={WorkRoom} key="WorkRoom" />
        <Route path="/Study" exact component={Study} key="Study" />
        <Route path="/Developer" exact component={Developer} key="Developer" />
        <Route path="/Portfolio" exact component={Portfolio} key="Portfolio" />
        <Route path="/Download" exact component={Download} key="Download" />
        <Route path="/DownloadMobile" exact component={DownloadMobile} key="DownloadMobile" />
        <Route path="/join" exact component={Join} key="Join" />
        <Route path="/chat" exact component={Chat} key="Chat" />
        <Route path="/Photo" exact component={Photo} key="Photo" />
        <Route path="/Timeline" exact component={Timeline} key="Timeline" />
        <Route path="/Board" exact component={Board} key="Board" />
        <Route path="/MemberJoin" exact component={MemberJoin} key="MemberJoin" />
        <Route path="/MyInfoUpdate" exact component={MyInfoUpdate} key="MyInfoUpdate" />
        <Route path="/FindMemberInfo" exact component={FindMemberInfo} key="FindMemberInfo" />
        <Route path="/MyPage" exact component={MyPage} key="MyPage" />
        <Route path="/FreeBoard/:NUM" render={(props) => (<Board {...props} Type={'FreeBoard'} />)} key="FreeBoard" />
        <Route path="/NewsBoard/:NUM" render={(props) => (<Board {...props} Type={'NewsBoard'} />)} key="NewsBoard" />
        <Route path="/QaBoard/:NUM" render={(props) => (<Board {...props} Type={'QaBoard'} />)} key="QaBoard" />
        <Route path="/DeveloperNoteBoard/:NUM" render={(props) => (<Board {...props} Type={'DeveloperNoteBoard'} key="DeveloperNoteBoard" />)} />
        <Route path="/Remote" exact component={Remote} key="Remote" />
        <Route path="/WorkCalendar" exact component={WorkCalendar} key="WorkCalendar" />
        {/* <Route path="/" exact element={<RoomList/>} /> */}
        <Route path="/room/:roomId" exact component={VideoChat}  key="room" />
          <MiniChatRoom socket={socket}/>
      </div>
      <footer className="App-footer">
        <img src={footerimg}></img>
      </footer>
    </div>
  );
}

export default App;


function MiniChatRoom({socket}) {
  
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState(uuidv4());
  const [room, setRoom] = useState('antsnest');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [flag, setFlag] = useState(0);
  const ENDPOINT = 'https://edu.antsnest.co.kr';

  useEffect(() => {
    socket.on('connect', () => {
      // 소켓이 서버에 연결된 후에 실행됩니다.
      socket.emit('join', { id: socket.id, name, room }, (error) => {
        if (error) {
          setFlag(1);
          alert(error);
        }
      });
      socket.on('message', message => {
        setMessages(messages => [...messages, message]);
      });
  
      socket.on("roomData", ({ users }) => {
        setUsers(users);
      });
    });
   
    return () => { socket.disconnect(); };
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();
    if (message) {
      socket.emit('sendMessage', { id: socket.id, message }, () => setMessage(''));
    }
  }

  if (flag) {
    return (
      <Redirect to="/" />
    )
  }

  
  
  const toggleExpand = (e) => {
    setExpanded(!expanded);
    if (!expanded) {
      e.target.parentNode.parentNode.style.height = '350px'; // Increase body height
      e.target.parentNode.parentNode.parentNode.style.bottom = '370px';


    } else {
      e.target.parentNode.parentNode.style.height = '50px'; // Restore original body height
      e.target.parentNode.parentNode.parentNode.style.bottom = '70px';
    }
  };

  return (
    <div style={{position:'absolute' ,bottom:'55px',right:'30px',width:'300px',height:'0px'}}>
      <section style={{overflow:'hidden',position:'sticky' , flexDirection:'column' , display:'flex' , alignItems:'center', justifyContent:'center' ,alignSelf:'center',borderRadius:'25px',background:'#fff',height:'50px',zIndex:'10',boxShadow:'2px 2px 2px silver'}}>
        <header style={{ display: 'flex', width: '80%', height: '15%', justifyContent: 'space-between' }}>
          <label style={{ display:'flex' , alignItems: 'center' }}> 채팅방 </label>
          <div
            style={{ alignSelf: 'center', width: '25px', height: '25px', borderRadius: '15px', background: '#fff', borderStyle: 'solid' }}
            onClick={toggleExpand}
          >
            
          </div>
        </header>

        {expanded && ( // Conditionally render chat room section if expanded is true
        <div style={{overflow:'hidden',width:'100%', height:'100%' , background: '#f0f0f0',  border: '1px solid #ccc', borderRadius: '5px' }}>
      <div className="outerContainer">
      <div className="ContainerBox">
        <div className="container">
          <InfoBar room={room} />
          <Messages messages={messages} name={name} />
          <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
          </div>
          </div>
          </div>
          {/* <TextContainer users={users} /> */}
        </div>)}
        </section>
      </div>
  );
}
