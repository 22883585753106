import React, { createRef } from 'react';
import Imgb1 from '../../../image/ImageSkill.gif';
import '../../style.css';
import bizskill from '../../../image/design/M_antsnest_bizskill.png';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

function BizSkill() {
  const BizPlan = createRef();
  const BizMember = createRef();
  const BizSkill = createRef();
  const MenuArray = [BizPlan, BizMember, BizSkill];
  const ActiveCheck = (Menu, MenuArray) => {
    try {
      MenuArray.map((Item, Index) => {
        if (Item.current.className === 'TabActive')
          Item.current.className = '';
      })
      if (Menu.current.className === 'TabActive')
        Menu.current.className = '';
      else
        Menu.current.className = 'TabActive';
    }
    catch (err) {

    }
  }

  let TabPageStyle = {
    display: 'flex',
    flexWrap: "wrap",
  };
  let SubNavStyle = {
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '0',
    flexWrap: "wrap",
    backgroundColor: '#fff',
  };
  let ListStyle = {
    display: 'flex',
    listStyleType: 'none',
    margin: '15px',
  };
  let BizMemberTitle = {
    padding: "2px",
    textAlign: "center",
    backgroundColor: "#474e5d",
    color: "white"

  }
  return (
    <div className='TabControl'>
      <div id='MobilelTabPage' style={TabPageStyle}>
        <ul id='MobileTabNavigater' style={SubNavStyle} >
          <li style={ListStyle}><Link to="/Company" className=""> 사업계획</Link></li>
          <li style={ListStyle}><Link to="/BizSkill" className="TabActive"> 기술이력</Link></li>
          <li style={ListStyle}><Link to="/BizMember" className=""> 구성원</Link></li>
        </ul>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='BizSkillMasterPanel'>
          <img src={bizskill} style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }}></img>
        </div>
      </div>
    </div>
  );
}

export default BizSkill;
