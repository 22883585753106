import React, { createRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import LinkBugBoard from './Bug/Mobile';
import LinkInquiryBoard from './Inquiry/Mobile';
import LinkSuggestionsBoard from './Suggestions/Mobile';
import Select from '@material-ui/core/Select';

var CategorySearch = undefined;

function Help(props) {
  const Bug = createRef();
  const Inquiry = createRef();
  const Suggestions = createRef();

  const MenuArray = [Bug, Inquiry, Suggestions];
  const [Category, setCategory] = useState(['ALL', 'NoData']);
  var [SelecedCategory, setSelectedCategory] = useState("");
  const ActiveCheck = (Menu) => {
    try {
      MenuArray.map((Item, Index) => {
        if (Item.current.className === 'TabActive')
          Item.current.className = '';
      })
      if (Menu.current.className === 'TabActive')
        Menu.current.className = '';
      else {
        Menu.current.className = 'TabActive';
        if (Menu.current.id === 'Bug') {
          getBugCategory();
          setSelectedCategory('ALL');
          ReactDOM.render(<LinkBugBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
        }
        else if (Menu.current.id === 'Inquiry') {
          getInquiryCategory();
          setSelectedCategory('ALL');
          ReactDOM.render(<LinkInquiryBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
        }
        else if (Menu.current.id === 'Suggestions') {
          getSuggestionsCategory();
          setSelectedCategory('ALL');
          ReactDOM.render(<LinkSuggestionsBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
        }
      }
    }
    catch (err) {
      alert(err);
    }
  }
  const getBugCategory = () => {
    fetch('/request/BugCategoryList', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
    ).then(res => res.json())
      .then((msg) => {
        setCategory(msg);
      })
      .catch(err => alert('BugCategoryList' + err));
  }
  const getInquiryCategory = () => {
    fetch('/request/InqueryCategoryList', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
    ).then(res => res.json())
      .then((msg) => {
        setCategory(msg);
      })
      .catch(err => alert('오류 InquiryCategoryList' + err));
  }
  const getSuggestionsCategory = () => {
    fetch('/request/SuggestionsCategoryList', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
    ).then(res => res.json())
      .then((msg) => {
        setCategory(msg);
      })
      .catch(err => alert('SuggestionsCategoryList' + err));
  }


  const CategoryRefrash = (handleSaveClick) => {
    CategorySearch = handleSaveClick;
  }
  const SelectedCategory = (e) => {
    setSelectedCategory(e.target.value);
    if (CategorySearch !== undefined)
      CategorySearch(e.target.value);
  }
  useEffect(() => {
    ActiveCheck(Inquiry);
  }, []);


  return (
    <div className='TabPage'>
      <ul className="SubNavCategory" style={{ borderStyle: 'solid', borderBottomStyle: 'none', borderColor: 'silver', borderWidth: '1px' }} >
        <li><a id='Inquiry' className="" ref={Inquiry} onClick={() => ActiveCheck(Inquiry)}> 문의사항</a></li>
        <li><a id='Bug' className="" ref={Bug} onClick={() => ActiveCheck(Bug)}> 버그리포트</a></li>
        <li><a id='Suggestions' className="" ref={Suggestions} onClick={() => ActiveCheck(Suggestions)}> 건의함</a></li>
      </ul>
      <div style={{ borderWidth: '1px', borderStyle: 'solid', borderBottomStyle: 'none', borderColor: 'silver' }} id='Board_View' />
    </div>
  )
}
export default Help;