import React, { createRef, useState, useRef, useEffect, useMemo } from 'react';
import ImageResize from "quill-image-resize-module-react";
import ReactQuill,{Quill} from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import { TableModule } from 'quill-table';
import './style.css';

window.Quill = Quill
let Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px']; // 사용 가능한 폰트 크기
const quillTable = require('quill-table');
Quill.register(Size, true);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register('modules/table', TableModule);
Quill.register("modules/imageResize", ImageResize);
function HtmlEditer(props) {

  const [TextEditerTitle, setTextEditerTitle] = useState('');
  const [SendData, setSendData] = useState({ "num": "", "id": "", "name": "", "title": "", "contents": "" });
  const [Session,setSession] = useState(props.Session);
  const quillRef = useRef();
  
  useEffect(() => { // 폼로드와 비슷한 개념
    console.log(props.Session);
    setSession(props.Session);
  }, [props.Session]);

  useEffect(() => { // 폼로드와 비슷한 개념
    const quill = quillRef.current.getEditor();
    setupDragAndDrop(quill);
  if (props.Data != undefined) {
    setSendData({ ...SendData, num: props.Data.NUM, title: props.Data.NOTICE_TITLE, category: props.Data.NOTICE_CATEGORY, contents: props.Data.NOTICE_CONTENTS, PUBLIC: props.Data.PUBLIC });
    setTextEditerTitle(props.Data.NOTICE_TITLE);
  }
  }, []);
  
  useEffect(() => { // 폼로드와 비슷한 개념
    if (props.Data != undefined) {
      setSendData({ ...SendData, num: props.Data.NUM, title: props.Data.NOTICE_TITLE, category: props.SelectCategory, contents: props.Data.NOTICE_CONTENTS, PUBLIC: props.Public });
      setTextEditerTitle(props.Data.NOTICE_TITLE);
    }
    else {
      setSendData({ ...SendData, category: props.SelectCategory, PUBLIC: props.Public });
    }
  }, [props.SelectCategory, props.Public]);

  const OnTextTitleChanged = (ev) => {
    setSendData({ ...SendData, title: ev.target.value })
    setTextEditerTitle(ev.target.value);
  }

  const imageHandler = () => {
    const quill = quillRef.current.getEditor();
    
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return; // Make sure a file was selected

      const formData = new FormData();
      const encodedFileName = encodeURIComponent(file.name);
      formData.append("img", file, encodedFileName);
      console.log('sendID::' + Session.UserId);
      formData.append('userId', Session.UserId);

      try {
          const response = await fetch(process.env.REACT_APP_UPLOAD_ENDPOINT + '/upload/AttachFileUpload', {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              body: formData,
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response.json();
          const range = quill.getSelection()?.index;
          if (typeof (range) !== "number") return; // Ensure we have a valid range

          const url = process.env.REACT_APP_API_ENDPOINT + '/antsnest/AttachFile/' + data.filename;
          // const img = `<img src="${url}" style="display: block; margin: 0 auto;">`; // Center the image
          // quill.clipboard.dangerouslyPasteHTML(range, img);
          // quill.setSelection(range + 1); // Move cursor after the image

            // 이미지 삽입과 동시에 중앙 정렬 스타일 적용
          quill.insertEmbed(range.index, 'image', url, Quill.sources.USER);
          quill.formatText(range.index, 1, 'align', 'center'); // 이미지에 중앙 정렬 스타일 적용

          quill.setSelection(range.index + 1, Quill.sources.SILENT); // 커서 위치를 이미지 뒤로 이동


          console.log('사진업로드 완료:' + JSON.stringify(data));
      } catch (err) {
          console.log('업로드 오류:', err.message);
      }
  };
  }
  function handleTableActions(action) {
    const quill = quillRef.current.getEditor();
    const tableMdl=quill.getModule('table');
    switch (action) {
      case 'insertTable':
        tableMdl.insertTable(3, 3); // 3x3 테이블 삽입
        break;
      case 'insertRowAbove':
        tableMdl.insertRowAbove();
        break;
      case 'insertRowBelow':
        tableMdl.insertRowBelow();
        break;
      case 'insertColumnLeft':
        tableMdl.insertColumnLeft();
        break;
      case 'insertColumnRight':
        tableMdl.insertColumnRight();
        break;
      case 'deleteRow':
        tableMdl.deleteRow();
        break;
      case 'deleteColumn':
        tableMdl.deleteColumn();
        break;
      case 'deleteTable':
        tableMdl.deleteTable();
        break;
      default:
        break;
    }
  }
    const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline', 'clean'],
        [{ 'image': 'imageResize' }, 'code-block'],
        [{ 'table': ['insertTable', 'insertRowAbove', 'insertRowBelow', 'insertColumnLeft', 'insertColumnRight', 'deleteRow', 'deleteColumn', 'deleteTable'] }] // Ensure this adds a table
      ],
      handlers: {
        'image': imageHandler, // Consolidate image handler
        'insertTable': () => handleTableActions('insertTable'),
        'insertRowAbove': () => handleTableActions('insertRowAbove'),
        'insertRowBelow': () => handleTableActions('insertRowBelow'),
        'insertColumnLeft': () => handleTableActions('insertColumnLeft'),
        'insertColumnRight': () => handleTableActions('insertColumnRight'),
        'deleteRow': () => handleTableActions('deleteRow'),
        'deleteColumn': () => handleTableActions('deleteColumn'),
        'deleteTable': () => handleTableActions('deleteTable')
      },
      
    },
    'table':true,
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"]
    },
  }), []);
 
  function setupDragAndDrop(quill) {
    const editorContainer = quill.container;
  
    // Dragover 이벤트 리스너: 드래그 중인 요소가 드롭 가능한 영역 위에 있을 때
    editorContainer.addEventListener('dragover', function (e) {
      e.preventDefault();  // 기본 동작 방지
    });
  
    // Drop 이벤트 리스너: 드래그한 요소를 드롭 했을 때
    editorContainer.addEventListener('drop', function (e) {
      e.preventDefault();  // 기본 동작 방지
  
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file.type.startsWith('image/')) {
          insertImageFromFile(file, quill);
        }
      }
    });
  }
  
  function insertImageFromFile(file, quill) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const range = quill.getSelection();
      if (range) {
        quill.insertEmbed(range.index, 'image', reader.result);
        quill.setSelection(range.index + 1);
      }
    });
    reader.readAsDataURL(file);
  }



  const rteChange = (content, delta, source, editor) => {
    var Text = editor.getHTML();
    //console.log(JSON.stringify(Text));
    setSendData({ ...SendData, contents: Text });
  }

  const Validate=()=>{
  // 제목과 내용이 비어있지 않은지 확인
    if (TextEditerTitle !== '' && SendData.contents !== '') {
      props.Submit(SendData);
    } else {
      // 필수 필드가 비어있는 경우 사용자에게 알림
      alert('제목과 내용은 필수로 입력해야 합니다.');
    }
  }
 


  return (
    <div>
      <main>
        <header style={{width:'100%' , display:'flex'}}>
         <input placeholder='제목을 입력하세요.' type='text' style={{width:'100%' ,padding:'10px',borderWidth:'thin',borderColor:'silver'}} value={TextEditerTitle} onChange={OnTextTitleChanged}></input> 
        </header>
        <article style={{textAlign:'left'}}>
        <ReactQuill ref={quillRef} modules={modules}
          placeholder={'글을 입력하세요.'}
          onChange={rteChange}
          defaultValue={props.Data === undefined ? '' : props.Data.NOTICE_CONTENTS}
        />
        </article>
      </main>
      <button type='button' onClick={() => Validate()} style={{display:'flex',justifyContent:'center',width:'100%',height:'5rem',alignItems:'center',fontSize:'1.3rem'}}>작성완료</button>
    </div>
  );
}

export default HtmlEditer;