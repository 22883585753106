import React, { useEffect, useState } from 'react';
import './style.css';

function DynamicSlideGallery({ images, Photo }) {
  const [slideIndex, setSlideIndex] = useState(1);
  const [myPhoto, setMyPhoto] = useState([]);

  useEffect(() => {
    // Photo가 비어 있지 않으면 상태 업데이트
    if (Photo && Photo.length) {
      setMyPhoto(Photo);
    }
  }, [Photo]); // Photo 배열이 변경될 때마다 실행

  useEffect(() => {
    // 슬라이드 쇼 시작
    const interval = setInterval(() => {
      setSlideIndex(prevIndex => prevIndex === myPhoto.length ? 1 : prevIndex + 1);
    }, 5000);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
  }, [myPhoto.length]); // myPhoto 배열의 길이가 변경될 때마다 실행

  const showSlides = n => {
    let newIndex = n;
    if (newIndex > myPhoto.length) newIndex = 1;
    else if (newIndex < 1) newIndex = myPhoto.length;
    setSlideIndex(newIndex);
  };

  // myPhoto 배열이 비어 있으면 아무 것도 렌더링하지 않음
  if (!myPhoto || myPhoto.length === 0) return null;

  return (
    <div className="GalleryContainer">
      {/* 슬라이드 쇼 컨텐츠 */}
      {myPhoto.map((img, index) => (
        <div
          className={`mySlides ${index + 1 === slideIndex ? 'slideActive' : ''}`}
          key={index}
          style={{ display: index + 1 === slideIndex ? 'block' : 'none' }}
        >
          <div className="numbertext">{`${index + 1} / ${myPhoto.length}`}</div>
          <img src={img.src || process.env.REACT_APP_API_ENDPOINT + '/antsnest/App/' + img.pix_title} className='defaultImg' alt={img.alt || '이미지 설명'} />
          <label style={{fontSize:'10px'}}>title:{img.pix_title}</label>
        </div>
      ))}
      
      {/* 이전/다음 버튼 */}
      <a className="prev" onClick={() => showSlides(slideIndex - 1)}>&#10094;</a>
      <a className="next" onClick={() => showSlides(slideIndex + 1)}>&#10095;</a>

      {/* 이미지 인디케이터 */}
      <div className="row">
        {myPhoto.map((_, index) => (
          <div className="column" key={index}>
            <div
              className={`demo cursor ${index + 1 === slideIndex ? 'demoActive' : ''}`}
              onClick={() => showSlides(index + 1)}
              alt="이미지 설명"
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DynamicSlideGallery;