import React, { createRef, useState, useEffect } from 'react';
import MyLogo from '../../../image/MyLogo.png';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


function MobileLogoutView(props) {
  const [profile, setProfile] = useState({ "fileName": "" });
  const [Session, setSession] = useState({UserId:'',Name:''});
  useEffect(() => {
    CreateSession();
    ReadProfile();
  }, []);
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setSession(msg) })
      .catch(err => alert(err + '오류'));
  }
  const LoginOut = () => {
    fetch('/request/Logout', { credentials: 'include' })
      .then(props.Refrash())
      .catch(err => alert(err + '로그아웃 오류'));
  }
  const changeProfile = () => {
    let fileinfo = document.getElementById("img").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      uploadImage();
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }
  // 프로필 사진 가져오기
  const ReadProfile = () => {
    fetch('/request/ReadProfile', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "UserId": Session.UserId })
    })
      .then(res => res.json())
      .then(msg => sendImage(msg))
      .catch(err => alert(err));
  }
  // 프로필 변경
  const uploadImage = () => {
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    formData.append('userId',Session.UserId);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/ProfileChange', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    }).then((res) => res.json())
      .then(() => ReadProfile())
      .catch(err => alert(err));
  }
  // 프로필 파일명 세팅
  const sendImage = (data) => {
    if (data.length === 0) {
      setProfile({ ...profile, fileName: 'NoImage.png' });
    }
    else {
      setProfile({ ...profile, fileName: data[0].FILE_NAME });
    }
  }

  useEffect(() => {
    CreateSession();
  }, []);
  return (

    <div className='MyInfoBox'>
      <table className='LoginTable'>
        <tr>
          <td colSpan='2'><Link to='/MyInfoUpdate'><img src={MyLogo} /></Link></td>
        </tr>
        <tr>
          <div className='UserInfo'>
            <div class='개인정보'>
              <div class='프로필사진' Link>
                <form id='myForm' method="POST" encType="multipart/form-data" >
                  <div className='사진파일_배경'>
                    <label for='img'><img className='프로필_이미지' src={process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + profile.fileName} /></label>
                    <input className='사진파일_히든' id='img' type="file" name="img" accept="image/*" onChange={() => changeProfile()} />
                  </div>
                </form>
              </div>
              <div class='계정정보'>
                <div><b>아이디</b> : {Session.UserId}</div>
                <div><b>사용자명</b> : {Session.Name}</div>
                <div><b>등급</b> : 회원</div>
              </div>
            </div>
            <button className='LoginButton' onClick={() => LoginOut()}>로그아웃</button>
          </div>
        </tr>
      </table>
    </div>
  );
}



export default MobileLogoutView;
