import {createContext, useReducer} from 'react';
export const HomeViewContext = createContext();

const initialState={

    LOT_GRID_ROWS:""

};

//리듀서
const reducer = (state,action) =>{

    switch(action.type) {

        case 'SET_LOT_GRID_ROWS' :

            return {...state,LOT_GRID_ROWS:action.value};

        default:

        throw new Error("컨텍스트에 속성값이 없습니다.");
    }
}

//CONTEXT HOC
export const withContext =  WrappedComponent => {

    const Component = (props) =>{

    const [context,dispatch] = useReducer(reducer,initialState);

    return (

    <HomeViewContext.Provider value= {{context,dispatch}}>

    <WrappedComponent {...props}/>

    </HomeViewContext.Provider>);

    };

    return Component;

    };