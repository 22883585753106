import React from 'react';

import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../../style.css';

function Download() {

  let TabPageStyle = {
    display: 'flex',
    flexWrap: "wrap",
  };
  let SubNavStyle = {
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '0',
    flexWrap: "wrap",
    backgroundColor: '#fff',
  };
  let ListStyle = {
    display: 'flex',
    listStyleType: 'none',
    margin: '15px',
  };
  let BizMemberTitle = {
    width: '100%',
    padding: "2px",
    textAlign: "center",
    backgroundColor: "#474e5d",
    color: "white"

  };
  return (
    <div className='Wrapper'>
      <div id='MobilelTabPage' style={TabPageStyle}>
        <ul id='MobileTabNavigater' style={SubNavStyle} >
          <li style={ListStyle}><Link to="/WindowsDown" className="active"> 윈도우앱</Link></li>
          <li style={ListStyle}><Link to="/MobileDown" className=""> 모바일앱</Link></li>
          <li style={ListStyle}><Link to="/UnityDown" className=""> 유니버셜앱</Link></li>
        </ul>
      </div>
      <div className='M_DownloadMasterPanel'>
        <h1>준비중입니다.</h1>
      </div>
    </div>
  );
}

export default Download;
