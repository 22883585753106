import React, {  useState, useEffect } from 'react';
import logininput from '../../../image/design/logininput.png'
import passwordinput from '../../../image/design/passwordinput.png'
import Loginboxtitle from '../../../image/design/Loginboxtitle.jpg';




function MiniLogin(props) {
  const [LoginData, setLoginData] = useState({ "UserId": "", "UserPw": "" });

  const Enter_Check = (event) => {
    if (event.keyCode == 13) {
      Login(LoginData)
    }
  }

  const LoginValidation = (msg) => {
    if (msg.ErrorMessage === -1) {
      alert("아이디가 없습니다.");
    } else if (msg.ErrorMessage === -2) {
      alert("비밀번호가 틀렸습니다.");
    }
  }

  const Login = (Data) => {
    if (Data.UserId === '') {
      alert('아이디를 입력하세요');
      return;
    }
    if (Data.UserPw === '') {
      alert('비밀번호를 입력하세요');
      return;
    }
    fetch('/request/Login', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    }
    ).then((res) => res.json())
      .then((msg) => LoginValidation(msg))
      //.then((msg) => { alert(JSON.stringify(msg))})
      .then(() => props.getSession())
      .catch(err => alert('Page 로그인 오류' + err));
  }
  useEffect(() => {
  }, []);
  return (
    <div className='UserInfo'>
    <div style={{ display: 'flex', height: '40px' }}><img src={Loginboxtitle} style={{borderStyle:'none'}} width='103px' height='19px'></img></div>
    <hr color='#eaeaea' />
          <table className='LoginTable'>
            <tbody>
              {/* 아이디 */}
              <tr>
                <td style={{ position: 'relative', height: '80px' }}>
                  <input type='text' className='User' style={{ paddingLeft: '60px', paddingRight: '20px', lineHeight: '50px', width: '325px', borderRadius: '30px', borderColor: '#eaeaea', borderStyle: 'solid' ,margin:'0'}} value={LoginData.UserId} placeholder={'아이디'} onChange={(ev) => setLoginData({ ...LoginData, UserId: ev.target.value })} onKeyDown={(ev) => Enter_Check(ev)} />
                  <img style={{ position: 'absolute', left: '30px', top: '26px' ,borderStyle:'none'}} src={logininput}></img>
                </td>
              </tr>
              {/* 비밀번호 */}
              <tr>
                <td style={{ position: 'relative', height: '80px' }}>
                  <input type='password' className='Password' style={{ paddingLeft: '60px', paddingRight: '20px', lineHeight: '50px', width: '325px', borderRadius: '30px', borderColor: '#eaeaea', borderStyle: 'solid' ,margin:'0'}} value={LoginData.UserPw} placeholder={'비밀번호'} onChange={(ev) => setLoginData({ ...LoginData, UserPw: ev.target.value })} onKeyDown={(ev) => Enter_Check(ev)} />
                  <img style={{ position: 'absolute', left: '30px', top: '27px' ,borderStyle:'none'}} src={passwordinput}></img>
                </td>
              </tr>
              {/* 로그인 버튼 */}
              <tr>
                <td colSpan='2'><button style={{ width: '325px', height: '50px', borderColor: '#eaeaea', borderStyle: 'solid', backgroundColor: '#00bed8', borderRadius: '30px', fontSize:'17px',cursor:'pointer'}} className='LoginButton' onClick={() => Login(LoginData)} >로그인</button></td>
              </tr>
            </tbody>
          </table>
          </div>
  );
}

export default MiniLogin;
