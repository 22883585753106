export var liStyle = { 
    backgroundColor: 'white', 
    borderColor: 'silver', 
    borderStyle: 'solid', 
    borderWidth: '1px', 
    borderBottomStyle: 'none', 
    borderTopLeftRadius: '10px', 
    borderTopRightRadius: '10px',
};

export var liLeftStyle = { 
    backgroundColor: 'white', 
    borderColor: 'silver', 
    borderStyle: 'solid', 
    borderWidth: '1px', 
    borderBottomStyle: 'none', 
    borderTopLeftRadius: '10px', 
    borderTopRightRadius: '10px',
    float:'right'
};