import React, { useState, useRef,useMemo,useEffect } from 'react';
import { Resizable } from 'react-resizable';


const GanttChartView = ({Session}) => {
  const [userSession ,setUserSession] = useState(Session);
  const chartStartDate = new Date('2024-01-01');
  const chartEndDate = new Date('2024-12-31');
  const [dragOverData, setDragOverData] = useState(null);
  const [width, setWidth] = useState(550); // 초기 너비 설정
  const getTaskList = ()=>{
    fetch('/request/SelectTaskList', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({Session:Session.UserId})
    }
    ).then(res => res.json())
    .then((msg) => { 
      setTasks(msg);
      setTimeout(() => {
      if (scrollContainerRef.current) {
        const today = new Date().toISOString().split('T')[0];
        const targetElement = scrollContainerRef.current.querySelector(`[data-date='${today}']`);
  
        if (targetElement) {
          const scrollPosition = targetElement.offsetLeft;
          targetElement.style.background='red';
          scrollContainerRef.current.scrollLeft = scrollPosition-900;
        }
      }
    }, 0);
    }).catch(err => alert('SelectTaskList' + err));
  }

  const scrollContainerRef = useRef(null);

  // 너비 조정 이벤트 핸들러
  useEffect(()=>{
    getTaskList();
    setUserSession(Session);
  
  },[Session])
  const handleResize = (event, { size }) => {
    setWidth(size.width);
  };
// 드래그 시작 시 날짜 정보 설정
const handleDragStart = (event, taskId, handleType) => {
  var sender= event.target;
  event.dataTransfer.setData("taskId", taskId); // 테스크 ID 설정
  event.dataTransfer.setData("handleType", handleType); // 드래그 핸들 타입 설정
  event.dataTransfer.setData("cellDate", sender); 
};

const handleDragEnd = (event, taskId, handleType,prevDate) => {
  event.preventDefault();
  // 드래그가 끝난 지점의 데이터 사용
  if (dragOverData) {
    const { cellDate } = dragOverData;
    // 여기에서 cellDate와 taskId, handleType을 사용하여 태스크 업데이트 로직을 수행
    setTasks((prevTasks) => {
      return prevTasks.map((task) => {
        if (task.id === taskId) {
          if (handleType === 'start') {
            // 시작 날짜가 종료 날짜보다 이후가 되지 않도록 함
            const newStartDate = new Date(cellDate) > new Date(task.endDate) ? task.endDate : cellDate;
            return { ...task, startDate: newStartDate };
          } else if (handleType === 'end') {
            // 종료 날짜가 시작 날짜보다 이전이 되지 않도록 함
            const newEndDate = new Date(cellDate) < new Date(task.startDate) ? task.startDate : cellDate;
            return { ...task, endDate: newEndDate };
          }
        }
        return task;
      });
    });
  }
  // 드래그 종료 후 드래그 오버 데이터 초기화
  setDragOverData(null);
};
  
const handleDragOver = (event) => {
  event.preventDefault(); // 드래그 오버 이벤트가 드랍 이벤트를 허용하도록 기본 동작을 방지
  const cellDate = event.target.getAttribute('data-date');
  if (cellDate) {
    setDragOverData({ cellDate });
  }
};
  
const newDate = (event, taskId,userSession) => {
  const cellDate = event.target.getAttribute('data-date'); // 클릭된 셀의 날짜를 가져옴
  if (!cellDate) return; // 셀 날짜가 없는 경우 함수 종료

  const startDate = new Date(cellDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6); // 시작 날짜로부터 7일 후를 계산

  setTasks((prevTasks) => prevTasks.map((task) => {
    if (task.id === taskId) {
      return {
        ...task,
        worker: userSession.UserId,
        startDate: startDate.toISOString().split('T')[0], // YYYY-MM-DD 형식으로 설정
        endDate: endDate.toISOString().split('T')[0], // YYYY-MM-DD 형식으로 설정
      };
    }
    return task;
  }));
};
  const [tasks,setTasks] = useState([
    { id: 1, title: '샘플 작업' , worker:'앤츠' ,startDate: '2024-01-01', endDate: '2024-01-07' },
    { id: 2, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 3, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 4, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 5, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 6, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 7, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 8, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 9, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 10, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 11, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 12, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 13, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 14, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 16, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 17, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 18, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 19, title: '-',worker:'' , startDate: '', endDate: '' },
    { id: 20, title: '-',worker:'' , startDate: '', endDate: '' },
  ]);
  // 전체 기간을 날짜로 나누어 테이블 헤더 생성
    // 날짜 헤더 생성
    const { monthHeaders, dayHeaders } = useMemo(()=>generateMonthAndDayHeaders(chartStartDate, chartEndDate));
  
    // 월별 및 일별 헤더 생성
    function generateMonthAndDayHeaders(startDate, endDate) {
      let currentDate = new Date(startDate.toISOString().substring(0, 10));
      let monthHeaders = [];
      let dayHeaders = [];
      let monthDayCount = {};
  
      while (currentDate <= endDate) {
        const monthYearKey = `${currentDate.getMonth()}-${currentDate.getFullYear()}`;
        monthDayCount[monthYearKey] = (monthDayCount[monthYearKey] || 0) + 1;
        dayHeaders.push(
          <th key={currentDate.toISOString()} style={{ width:'30px',height:'27px',padding: '5px', border: '1px solid #ddd' }}>
            {currentDate.getDate()}
          </th>
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }
  
      Object.keys(monthDayCount).forEach(key => {
        const [month, year] = key.split('-');
        const monthName = new Date(year, month, 1).toLocaleString('default', { month: 'long' });
        monthHeaders.push(
          <th key={key} colSpan={monthDayCount[key]} style={{ padding: '4.5px', border: '1px solid #ddd', textAlign: 'center' }}>
            {`${monthName} ${year}`}
          </th>
        );
      });
  
      return { monthHeaders, dayHeaders };
    }
    const generateTableRows = (tasks, chartStartDate, chartEndDate,userSession) => {
      return tasks.map(task => {
        const taskStartDate = new Date(task.startDate);
        const taskEndDate = new Date(task.endDate);
        let currentDate = new Date(chartStartDate);
        const cells = [];
    
        // 전체 날짜 범위에 대해 셀 생성
        while (currentDate <= chartEndDate) {
          const isWithinTaskDuration = currentDate >= taskStartDate && currentDate <= taskEndDate;
          const cellKey = `${task.id}-${currentDate.toISOString()}`;
          const cellDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD 형식
          
          cells.push(
            <td key={cellKey}
            id='taskCell'
            //onDrop={(e) => handleDragEnd(e, task.id, cellDate,e)}
            style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center', width: '30px',height:'27px'}}>
            {isWithinTaskDuration ? (
              <div
                //draggable="true"
                id='taskCell'
                data-date={cellDate} // 각 셀에 날짜 데이터 속성 추가
                onDragStart={(e) => handleDragStart(e, task.id)}
                onDragOver={handleDragOver}
                //onDrop={(e) => handleDragEnd(e, task.id, cellDate,e)}
                style={{ position:'relative',backgroundColor: '#007bff', height: '100%', cursor: 'move',color:'#fff' }}
              >
                    {task.startDate === cellDate ?<DragHandle handleType="start" onDragStart={handleDragStart} onDragEnd={(e,taskId,handleType)=>handleDragEnd(e,taskId,handleType,cellDate)} taskId={task.id} />:''}
                    {task.endDate === cellDate ?<DragHandle handleType="end" onDragStart={handleDragStart}  onDragEnd={(e,taskId,handleType)=>handleDragEnd(e,taskId,handleType,cellDate)} taskId={task.id} />:''}
                {currentDate.getDate()}
              </div>
            ) : (
              <div
                draggable
                onClick={(e) => newDate(e, task.id,userSession)}
                data-date={cellDate} // 각 셀에 날짜 데이터 속성 추가
                onDragOver={handleDragOver}
                style={{width: '30px'}}>
                {currentDate.getDate()}
               </div>
            )}
          </td>
          ); 
          currentDate.setDate(currentDate.getDate() + 1);
        }
    
        return (
          <tr key={task.id}>
            {/* 테스트 리스트 시작 */}
            {/* <td style={{ width:'30px',padding: '5px', border: '1px solid #ddd' }}>
                <TaskSection task={task}/>
            </td> */}
            {cells}
          </tr>
        );
      });
    };
    
    // 태스크 행 생성
    const taskRows = useMemo(() => generateTableRows(tasks, chartStartDate, chartEndDate,userSession));    
    const handleStyle = {
      position:'absolute',
      background: 'silver',
      height:'100%',
      width: '2px',
      cursor: 'col-resize',
      right:'0px',
      top:'0px',
      margin:'0px',
    };
    return (
      <div style={{display:'flex',flexDirection:'row'}}>
        <aside>
        <table style={{fontSize:'12px', width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
                <Resizable 
                          width={width}
                          height={'100%'}
                          onResize={handleResize}
                          handle={<div style={{position:'initial',margin:'0px'}}>
                          <span style={handleStyle}
                            onClick={(e) => {
                              // 드래그 핸들 클릭 시 이벤트 버블링 방지
                            e.stopPropagation();
                            }}
                          /></div>
                        }
                        draggableOpts={{ grid: [5, 5] }} // 드래그 시 이동 간격 조정 (선택사항)
                      >
                  <th  style={{ display:'flex', width: `${width}px`, padding:'4.5px',border: '0px solid #ddd',borderBottomWidth:'1px',borderTopWidth:'1px' ,justifyContent:'center'}}>--- 작업 정보 ---</th>
                </Resizable>
            </tr>
            <tr>
            <Resizable 
                          width={width}
                          height={'100%'}
                          onResize={handleResize}
                          handle={<div style={{position:'initial',margin:'0px'}}>
                          <span style={handleStyle}
                            onClick={(e) => {
                              // 드래그 핸들 클릭 시 이벤트 버블링 방지
                            e.stopPropagation();
                            }}
                          /></div>
                        }
                        draggableOpts={{  grid: [5, 5]}} // 드래그 시 이동 간격 조정 (선택사항)
                      >
            <th style={{  padding:'4.5px',border: '0px solid #ddd',borderBottomWidth:'1px',borderTopWidth:'1px'}}> 
                <section style={{display:'flex',alignItems:'baseline'}}>
                  <div style={{width:'5%'}}>
                    :
                  </div>  
                  <div style={{width:'5%',margin:'1px'}}>
                    NO
                  </div>  
                  <div style={{width:'25%'}}>
                    작업명
                  </div>   
                  <div style={{width:'25%'}}>
                    작업자
                  </div>   
                  <div style={{width:'40%'}}>
                    기간
                  </div>   
                  </section> 
                  </th>
                  </Resizable>
                </tr>
                <tr style={{height:'27px'}}>
                <td style={{ width:'30px',height:'32px',border: '0px solid #ddd',padding:'0px' }}>
                {tasks.map(task =>{
                return(
                  <TaskSection task={task} Session={userSession} handleResize={handleResize} handleStyle={handleStyle} setWidth={setWidth} width={width}/>
                )
                })}
                </td>
                </tr>
          </thead>
        </table>
        </aside>
        <section  ref={scrollContainerRef}  style={{overflowX:'scroll', width: '100%'}}>
          <table style={{fontSize:'12px', width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {monthHeaders}
              </tr>
              <tr>
                {dayHeaders}
              </tr>
            </thead>
            <tbody>
              {taskRows}
            </tbody>
          </table>
        </section>
      </div>
    );
  };

//========================================================================//
// TaskSection.js
  function TaskSection({ task ,Session,width,handleResize ,handleStyle}) {
    const [modifyMode,setModifyMode] = useState(false);
    const [title,setTitle] =useState(task.title);
    const [worker,setWorker] =useState(task.worker);
    useEffect(()=>{
      setWorker(task.worker);
      setTitle(task.title);
    },[task])
    useEffect(()=>{
      
    },[Session])
    const setTaskRow = (task)=>{
      console.log(JSON.stringify(task));
      fetch('/request/taskRow', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({id:task.id,title:title,worker:worker,startDate:task.startDate ,endDate:task.endDate})
      }
      ).then(res => res.json())
      .catch(err => alert('taskRow' + err));
      setModifyMode(!modifyMode);
    }

    return (
      <Resizable 
      width={width}
      height={'100%'}
      onResize={handleResize}
      handle={<div style={{position:'initial',margin:'0px'}}>
      <span style={handleStyle}
        onClick={(e) => {
          // 드래그 핸들 클릭 시 이벤트 버블링 방지
        e.stopPropagation();
        }}
      /></div>
    }
    draggableOpts={{ grid: [5, 5] }} // 드래그 시 이동 간격 조정 (선택사항)
  >
      <section style={{display:'flex',height:'27px',alignItems:'center', border:'0px solid #ddd',borderBottomWidth:'1px'}}>
        <div style={{width:'5%',paddingLeft:'7px'}}>
            <button style={{fontSize:'0.5rem',width:'13px',padding:'0px 3px'}} onClick={()=>
              setTaskRow(task)
              }>E</button>
        </div>  
          <div style={{width:'5%'}}>
          {task.id}
          </div>    
        <div style={{width:'25%'}}>
        {modifyMode? <input style={{width:'60%',borderStyle:'none',borderBottomStyle:'solid'}} defaultValue={title} type='text'onChange={(e)=>setTitle(e.target.value)}></input>:title}
        </div>   
        <div style={{width:'25%'}}>
        {modifyMode ? <input style={{width:'60%',borderStyle:'none',borderBottomStyle:'solid'}} defaultValue={worker} type='text'onChange={(e)=>setWorker(e.target.value)}></input>:worker}
        </div>   
        <div style={{width:'40%'}}>
          {task.startDate}~{task.endDate}
        </div>   
      </section>      
      </Resizable>     
    );
  }
//========================================================================//
  // DragHandle.js
function DragHandle({ onDragStart, onDragEnd, handleType, taskId }) {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, taskId, handleType)}
      onDragEnd={(e) => onDragEnd(e, taskId, handleType)}
      style={{
        color:'#fff',
        fontWeight:'bold',
        width: '10px',
        height: '100%',
        backgroundColor: 'silver',
        cursor: 'ew-resize',
        position: 'absolute',
        top: 0,
        ...(handleType === 'end' ? { right: '-5px' } : { left: '-5px' }),
      }}
    >{handleType==='end'? '>':'<'}</div>
  );
}

export default GanttChartView;