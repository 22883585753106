import session from 'express-session';
import React, { createRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import HtmlEditer from '../../HtmlEditer';
import './style.css';

function WriteMode(props) {
  const [Session, setSession] = useState({});
  const [SendData, setSendData] = useState({ "num": "", "id": "", "name": "", "title": "", "contents": "" });
  const [Category, setCategory] = useState(['ALL', 'NoData']);
  const [CategoryValue, setCategoryValue] = useState(props.Data === undefined ? 'ALL' : props.Data.NOTICE_CATEGORY);
  const [IsPublicValue, setIsPublicValue] = useState(props.Data === undefined ? '공개' : props.Data.Public === 1 ? '공개' : '비공개');
  useEffect(() => { // 폼로드와 비슷한 개념
    CreateSession();
    if (props.Data != undefined) {
      setSendData({ ...SendData, title: props.Data.NOTICE_TITLE, contents: props.Data.NOTICE_CONTENTS });
    }
  }, []);

  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setSession(msg) })
      .catch(err => alert(err + '오류'));
  }

  const Link = () => {
    ReactDOM.render(<div />, document.getElementById('Board_Contents'));
    document.getElementById('Board_Contents').setAttribute('class', 'Contents_Deactive');
  };

  const DataSubmit = (SendData) => {
    if (props.Data === undefined)
      fetch('/request/InsertDeveloperNoteBoard', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(SendData)
      }
      ).then(props.Refrash)
        .then(Link).catch(err => alert('DataInsert' + err));
    else
      fetch('/request/UpdateDeveloperNoteBoard', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "NUM": props.Data.NUM, "title": SendData.title, "contents": SendData.contents })
      }
      ).then(props.Refrash).then(Link).catch(err => alert('UpdateItem' + err));
  }
  return (
    <div>
      <div style={{padding:'30px'}}>
        <section style={{height:'38px',display:'flex'}}>
                <input placeholder='아이디' style={{width:'33.33%',borderRadius:'5px',borderWidth:'1px', padding:'0.5rem' ,borderColor:'silver'}} type='text' value={Session.UserId == undefined ? SendData.name : Session.Name} onChange={(ev) => setSendData({ ...SendData, name: ev.target.value })}></input>
                <select placeholder='카테고리' style = {{width:'33.33%', padding:'0.5rem' ,borderColor:'silver'}}value={CategoryValue} defaultValue={CategoryValue} onChange={(e) => setCategoryValue(e.target.value)} type='text'>
                  {Category.map((Data, i) => (<option value={Data.NOTICE_CATEGORY} key={i}>{Data.NOTICE_CATEGORY}</option>))}
                </select>
                <select style={{ width:'33.33%',padding:'0.5rem' ,borderColor:'silver'}} placeholder='공개범위' value={IsPublicValue} onChange={(ev) => setIsPublicValue(ev.target.value)} type='text'>
                  <option>공개</option>
                  <option>비공개</option>
                </select>
          </section>
          <HtmlEditer Submit={DataSubmit} setData={setSendData} Data={props.Data} />
        </div>
    </div>
  )
}


export default WriteMode;