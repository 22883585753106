import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreateRoom from "../CreateRoom";
import "./RoomList.css"; // Import the CSS file
import rooms01 from '../../image/rooms_01.jpg'
import roomsUser from '../../image/user_icon.png'
import roomsTime from '../../image/time_icon.png'
import roomsLock from '../../image/lock_icon.png'
import CountDown from '../../Common/Component/CountDown';

const RoomList = () => {
  
  const [rooms, setRooms] = useState([{id:'test', name:'Test_Meeting Title_01',participant:'4/5(참여자1,참여자2,참여자3...)', time:1720 }]);
 
  useEffect(() => {
    function fetchRooms() {
      console.log('Get room list');
      fetch("/request/rooms")
        .then(response => {
          console.log(response);
          return response.json();
        })
        .then(roomsData => {
          setRooms(roomsData);
        })
        .catch(err => {
          console.log(err);
        });
    }
    fetchRooms();
  }, []);

  return (
    <div className="room-list-container">
      <CreateRoom></CreateRoom>
      <div className="room-list-top">
        <h1>채팅방 목록</h1>
        <select>
          <option>모든 회의실</option>
          <option>회의중</option>
          <option>회의 시작전</option>
        </select>
      </div>
      {(!Array.isArray(rooms))? <div> 로딩중...</div>:      rooms.map((room,idx) => (
        <div key={room.id} to={`/room/${room.id}`} className="room-item">
          <div className="img-container">
            <img src={rooms01}/>
            <p>{idx+1}</p>
          </div>
          <div className="rooms-info">
            {room.name}
            <div style={{display:'flex', fontSize:'1rem', margin:'3% 0', fontWeight:'normal'}}>
              <img src={roomsUser}/>
              {room.participant}
            </div>
            <div style={{display:'flex', fontSize:'1rem', width:'200%', margin:'5% 0 0', fontWeight:'normal'}}>
              <img src={roomsTime}/>
              {'회의 남은 시간: '} <CountDown seconds={parseInt(room.time)}/>
              {/* {room.time} */}
            </div>
          </div>
          <Link to={`/room/${room.id}`}>
          <button style={{width:'100%'}} className="JoinBtn">회의 참여하기</button>
          </Link>
        </div>
      ))
      }
      

    </div>
  );
};

export default RoomList;