import React, { useState, useEffect } from "react";
//import queryString from 'query-string';
import io from "socket.io-client";
import { Redirect } from 'react-router';

import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

import './Chat.css';

let socket;

const Chat = ({ location }) => {
  const [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [flag, setFlag] = useState(0);
  const ENDPOINT = 'https://antsnest.co.kr:4000';

  useEffect(() => {
    if (name === '' || name === undefined) {
      //const { name, room } = queryString.parse(location.search);
      socket = io(ENDPOINT);

      setRoom(room);
      setName(name);

      socket.emit('join', { name, room }, (error) => {
        if (error) {
          setFlag(1);
          alert(error);
        }
      });
    }
  }, [ENDPOINT, location.search]);

  useEffect(() => {
    socket.on('message', message => {
      setMessages(messages => [...messages, message]);
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
    return () => { socket.disconnect(); };
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();
    if (message) {
      socket.emit('sendMessage', message, () => setMessage(''));
    }
  }

  if (flag) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <div className="outerContainer">
      <div className="ContainerBox">
        <div className="container">
          <InfoBar room={room} />
          <Messages messages={messages} name={name} />
          <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
        </div>
        <TextContainer users={users} />
      </div>
    </div>
  );
}

export default Chat;
