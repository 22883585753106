import './style.css';
import React, { createRef } from 'react';

import ParkParkMy from '../../../image/ParkParkMy.jpg';
import butters from '../../../image/butters.jpg';
import Proudin from '../../../image/Proudin.jpg';
import Future from '../../../image/Future.jpg';
import Woojong from '../../../image/Woojong.jpg';
import Hollis from '../../../image/Hollis.jpg';
import pghpgh from '../../../image/pghpgh.png';
import Eunji from '../../../image/Eunji.png';
import Yeonsu from '../../../image/yeonsu.png';
import Dasom from '../../../image/Dasom.jpg';
import Nahee from '../../../image/Nahee.jpg';
import Dexterity from '../../../image/Dexterity.jpg';
import BizAboutUs from '../../../image/design/ANTSNEST_AboutUs.png';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import HrCard from '../../../Common/Component/HrCard';

function BizMember() {
  const BizPlan = createRef();
  const BizMember = createRef();
  const BizSkill = createRef();
  const MenuArray = [BizPlan, BizMember, BizSkill];
  const ActiveCheck = (Menu, MenuArray) => {
    try {
      MenuArray.map((Item, Index) => {
        if (Item.current.className === 'TabActive')
          Item.current.className = '';
      })
      if (Menu.current.className === 'TabActive')
        Menu.current.className = '';
      else
        Menu.current.className = 'TabActive';
    }
    catch (err) {

    }
  }
  return (
    <div className='Wrapper'>
      <div className='TabControl'>
        <div className='TabPageBig'>
          <ul className="SubNavLeft">
            <li><Link to="/Company" ref={BizPlan} id='BizPlan' className="" onClick={() => ActiveCheck(BizPlan, MenuArray)}> 사업계획 </Link></li>
            <li><Link to='/BizSkill' ref={BizSkill} id="BizSkill" className="" onClick={() => ActiveCheck(BizSkill, MenuArray)}>기술이력 </Link></li>
            <li><Link to="/BizMember" ref={BizMember} id='BizMember' className="TabActive" onClick={() => ActiveCheck(BizMember, MenuArray)}> 구성원</Link></li>
          </ul>
          <div id='BizMemberView'>
            <div style={{ borderRadius: '0px 0px 30px 30px' }}>
              <img src={BizAboutUs} style={{ width: 'auto' }}></img>
            </div>
            <div className="Bizrow">
              <div className="Bizcol">
              <HrCard {...{
                Photo:Dexterity , 
                Name:'용수현' , 
                Work:'Project Leader' , 
                Title:'프로젝트 그룹 대표' , 
                Work2:'Rnd 개발,인프라 담당' ,
                Email:'ysh1103korea@antsnest.co.kr',
                }}/>
              </div>
              <div className="Bizcol">
                <HrCard {...{
                  Photo:Proudin , 
                  Name:'박상욱' , 
                  Work:'Project Leader',
                  Title:'프로젝트 그룹 대표',
                  Work2:'서버 및 데이터베이스 담당' ,
                  Email:'psw88@antsnest.co.kr',
                  }}/>
              </div>
              <div className="Bizcol">
                <HrCard {...{
                  Photo:butters , 
                  Name:'최용호' , 
                  Work:'Project Member',
                  Title:'Web&App Developer',
                  Work2:'Spa 웹앱 개발 담당' ,
                  Email:'butters88@naver.com',
                  }}/>
              </div>
            </div>
            <div className="Bizrow">
            <div className="Bizcol">
                <HrCard {...{
                  Photo:ParkParkMy , 
                  Name:'박명준' , 
                  Work:'Project Member',
                  Title:'Application Developer',
                  Work2:'Java / .Net 응용 개발' ,
                  Email:'ParkParkMy@antsnest.co.kr',
                  }}/>
              </div>
              <div className="Bizcol">
                <HrCard {...{
                  Photo:Future , 
                  Name:'이혜림' , 
                  Work:'Project Member',
                  Title:'Application Developer',
                  Work2:'WPF /.Net 응용 개발' ,
                  Email:'gPfla8966@gmail.com',
                  }}/>
              </div>
              <div className="Bizcol">
                <HrCard {...{
                  Photo:Woojong , 
                  Name:'진우종' , 
                  Work:'Project Member',
                  Title:'Web&App Developer',
                  Work2:'Spa 웹앱 개발 담당' ,
                  Email:'woojong67@naver.com',
                  }}/>
              </div>
            </div>
            <div className="Bizrow">
              <div className="Bizcol">
              <HrCard {...{
                  Photo:Hollis , 
                  Name:'김연주' , 
                  Work:'Project Member',
                  Title:'3D Designer',
                  Work2:'3D 모델링 리소스' ,
                  Email:'duswn9211@naver.com',
                  }}/>
              </div>
              <div className="Bizcol">
                <HrCard {...{
                  Photo:pghpgh , 
                  Name:'박경화' , 
                  Work:'Project Member',
                  Title:'리눅스,데이터베이스',
                  Work2:'인프라 구축 설계' ,
                  Email:'rudghk435@naver.com',
                  }}/>
              </div>
              <div className="Bizcol">
              <HrCard {...{
                  Photo:Eunji , 
                  Name:'이은지' , 
                  Work:'Project Member',
                  Title:'App&Web Developer',
                  Work2:'프론트&백엔드 개발' ,
                  Email:'ieunji470@antsnest.co.kr',
                  }}/>
              </div>
              
            </div>
            <div className="Bizrow">
              <div className="Bizcol">
              <HrCard {...{
                  Photo:Yeonsu , 
                  Name:'이연수' , 
                  Work:'Project Member',
                  Title:'App&Web Developer',
                  Work2:'프론트&백엔드 개발' ,
                  Email:'djfksdl@antsnest.co.kr',
                  }}/>
              </div>
              <div className="Bizcol">
              <HrCard {...{
                  Photo:Dasom , 
                  Name:'김다솜' , 
                  Work:'Project Member',
                  Title:'Marketing&UI/UX Designer',
                  Work2:'마케팅, UI/UX디자인' ,
                  Email:'som1213@antsnest.co.kr',
                  }}/>
              </div>
              <div className="Bizcol">
              <HrCard {...{
                  Photo:Nahee , 
                  Name:'김나희' , 
                  Work:'Project Member',
                  Title:'Component Developer',
                  Work2:'FrameWork Architecture' ,
                  Email:'madnarii@antsnest.co.kr',
                  }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BizMember;
