import { Alarm } from '@material-ui/icons';
import React, { createRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FreeBoard from './FreeBoard';
import NewsBoard from './NewsBoard';
import QaBoard from './QaBoard';
import * as action from './BoardAction';
import { ActiveCheck, ActiveFind } from './BoardAction';
import DeveloperNoteBoard from './DeveloperNoteBoard';
import './style.css';

function Board(props) {
  const News = createRef();
  const Free = createRef();
  const QA = createRef();
  const DeveloperNote = createRef();
  const MenuArray = [News, Free, QA, DeveloperNote];

  useEffect(() => {
    var Data = { "NUM": props.match.params.NUM, "NOTICE_NAME": "", "NOTICE_TEXT": "" }
    action.InitializeScreen(props, Data, MenuArray);
  }, []);
  return (
    <div className='Wrapper'>
      <div className='TabControl'>
        <div className='TabPage'>
          <ul className="SubNav">
            <li><a id='NewsBoard' className="" ref={News} href="#NewsBoard" onClick={() => ActiveCheck(News, MenuArray)}> 공지사항</a></li>
            <li><a id='FreeBoard' className="TabActive" ref={Free} href="#FreeBoard" onClick={() => ActiveCheck(Free, MenuArray)}> 자유게시판</a></li>
            <li><a id='DeveloperNoteBoard' className="" ref={DeveloperNote} href="#DeveloperNoteBoard" onClick={() => ActiveCheck(DeveloperNote, MenuArray)}> 개발자노트</a></li>
            <li><a id='QaBoard' className="" ref={QA} href="#QnA" onClick={() => ActiveCheck(QA, MenuArray)}> Q&A</a></li>
          </ul>
          <div id='Board_View' />
        </div>
      </div>
    </div>

  );
}

export default Board;