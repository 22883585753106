import { FeedbackRounded, SpeakerNotes } from '@material-ui/icons';
import React, { createRef, useRef, useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import '../M.style.css';

let LIMIT = 2;
let offset = 0;
let FeedList = [];
function MyPicture(props) {
  const PhotoSubmit = createRef(); // dom 에서 ref 기능으로 변경
  const [SendData, setSendData] = useState({ "num": "", "name": "", "write": "", "contents": "", "textCommentIndex": "" });
  const [Feed, setFeed] = useState([{}]);
  const [Comment, setComment] = useState({});
  const [Session, setSession] = useState({});
  const [profile, setProfile] = useState({ "fileName": "NoAvatar.png" });
  const [isListEnd, setIsListEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (isListEnd === false)
        GetFeedPhoto_ORG();
    }

  }, []);
  //EVENT=============================//
  useEffect(() => {
    ReadProfile();
  }, []);
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "10px",
      threshold: 1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setSession(msg) })
      .catch(err => alert(err + '오류'));
  }
  // 프로필 파일명 세팅
  const sendImage = (data) => {
    if (data.length === 0)
      setProfile({ ...profile, fileName: 'NoAvatar.png' });
    else
      setProfile({ ...profile, fileName: data[0].FILE_NAME });
  }
  // 프로필 사진 가져오기
  const ReadProfile = () => {
    fetch('/request/ReadProfile', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "UserId": Session.UserId })
    })
      .then(res => res.json())
      .then(msg => sendImage(msg))
      .catch(err => alert(err));
  }
  const uploadImgPreview = () => {
    let fileinfo = document.getElementById("img").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      document.getElementById("thumbnailImg").src = reader.result;
      document.getElementById("thumbnailImg").width = '150';
      document.getElementById("thumbnailImg").height = '150';
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }

  // 게시글 목록 초기화
  const clearData = (i) => {
    PhotoSubmit.current.disabled = false;
    document.getElementById("img").value = null;
    document.getElementById("TextInput").value = null;
    document.getElementById("thumbnailImg").src = null;
    document.getElementById("thumbnailImg").width = '0';
    document.getElementById("thumbnailImg").height = '0';

    if (i !== undefined) {
      document.getElementById(`textComment${i}`).value = '';
    }

    GetFeedPhoto_ORG();
  }

  // 게시글 전체 불러오기
  const GetFeedPhoto_ORG = () => {
    setLoading(true);
    fetch('/request/GetFeedPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then(msg => {
        FeedList = FeedList.concat(msg.slice(offset, offset + LIMIT));
        return FeedList;
      })
      .then((newData) => {
        offset = offset + LIMIT;
        if (offset > newData.length) {
          setIsListEnd(true);
        }
        console.log("오프셋:" + offset + "피드" + newData.length);
        setFeed(newData);
        setLoading(false);
      })
      .catch(err => { alert(err + '오류'); setLoading(false); });
  }

  // 댓글 등록 후 재조회
  const InsertComment = (Data) => {
    fetch('/request/InsertPhotoComment', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .then(() => clearData(Data.textCommentIndex))
      .catch(err => alert('InsertComment' + err));
  }

  const InsertSetsendData = (Data, i) => {
    var commentData = { ...SendData, "num": Data.pix_num, "textCommentIndex": i };
    InsertComment(commentData);
  }

  // 댓글 삭제 후 재조회
  const DeleteComment = (Data) => {
    fetch('/request/DeletePhotoComment', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .then(() => clearData())
      .catch(err => alert('DeleteComment' + err));
  }

  // 사진 업로드
  const uploadImage = () => {
    PhotoSubmit.current.disabled = 'disabled';
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/Image', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    }).then((res) => res.json())
      .then((msg) => { clearData(); })
      .catch(err => alert(err));
  }

  useEffect(() => {
    CreateSession();
    GetFeedPhoto_ORG();
  }, []);
  const autoGrow = (element) => {
    element.target.style.height = "1px";
    element.target.style.height = (20 + element.target.scrollHeight) + "px";
  }
  return (
    <div>

      <div className='M_WriteModeBox'>
        <div className='SubmitControl'>
          <form id='myForm' method="POST" encType="multipart/form-data" >
            <div className='ThumbnailView'><img id="thumbnailImg" src="" /><div id="thumbnailSrc" src="" /></div>
            <div class='작성자정보'>
              <textarea onInput={(e) => autoGrow(e)} style={{ resize: 'none', borderBlockStyle: 'none', overflow: 'hidden', minHeight: '60px', Height: '60px', borderStyle: 'solid', minWidth: '100%', borderWidth: '10px', borderColor: 'white', padding: '10px' }} placeholder="내용을 입력해주세요" id='TextInput' name="TextInput" className='사진_본문입력기' value={SendData.contents} onChange={(ev) => setSendData({ ...SendData, contents: ev.target.value })}></textarea>
            </div>
            <hr />
            <input id='img' type="file" name="img" onChange={() => uploadImgPreview()} />
            <hr />
          </form>
          <button ref={PhotoSubmit} className='SubmitButton' onClick={() => uploadImage()}>게시</button>
        </div>
      </div>

      {Feed.map(function (Data, i) {
        return (
          <div className='M_ReadModeBox'>
            <div className='ImgScroll' >
              <div className='M_WriteModeHeader' style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px', backgroundColor: 'whitesmoke' }}>
              {console.log('초기값:'+Data.FILE_NAME)}
              <img style={{ marginLeft: '10px', marginTop: '10px' }} id='모바일썸네일' className='프로필_이미지_모바일' src={Data.FILE_NAME===undefined?process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + 'NoAvatar.png':process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' +Data.FILE_NAME} />
                <label style={{ marginLeft: '10px', lineHeight: '90px', fontWeight: 'bold', fontSize: '20px' }}>{Session.UserId}</label>
              </div>
              <div className='imgPixHeader'>
                {Data.pix_mimetype !== undefined && Data.pix_mimetype.toString().indexOf("video/") !== -1 ?
                  <video controls width="100%">
                    <source src={Data.pix_loadPath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
                  </video>
                  :
                  <img width="100%" src={Data.pix_loadPath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoAvata.png'} />
                }
              </div>
              <h4>{Data.pix_contents}</h4>
              <div id='Comment' className="CommentBox">
                {Feed[i].Comment === undefined ? '' : <h3>댓글 : {Feed[i].Comment.length} 개</h3>}
                <div className='댓글_목록'>
                  {Feed[i].Comment === undefined ? '' : Feed[i].Comment.map(function (commentList, j) {
                    return (
                      <spen>
                        <li className='댓글_li'><b>{commentList.USER_NAME}</b>
                          <div className='댓글_div'>
                            <div>
                              {commentList.CONTENTS}
                              <div className='댓글_시간_div'>{commentList.CREATE_TIME}</div>
                            </div>
                            {Session.UserId === commentList.USER_ID ? <button className="댓글삭제_버튼" onClick={() => DeleteComment(Feed[i].Comment[j])}>삭제</button> : ''}
                          </div>
                        </li>
                        <hr color='#D8D8D8' size="1" />
                      </spen>
                    );
                  })}
                  <b>{Session.Name}</b> {/* 댓글 작성자*/}
                  <br />
                  {Session.UserId == undefined ? <div>로그인을 해야 댓글 작성이 가능합니다.</div>
                    :
                    <div className='댓글_div'>
                      <textarea id={`textComment${i}`} key={i} className="댓글_textarea" onChange={(ev) => setSendData({ ...SendData, "commentText": ev.target.value, })} /> {/* 댓글 내용*/}
                      <button className="댓글쓰기_버튼" onClick={() => InsertSetsendData(Data, i)}> 등록 </button><br />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {loading && <p>Loading...</p>}
      <div ref={loader} />
    </div>
  );
}

export default MyPicture;