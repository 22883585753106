import React, { createRef, useState, useEffect } from 'react';
import MiniLogin from '../../../../Common/Component/LoginBox/MiniLogin';
import ImgInput from '../../../../Common/Component/ImgInput';


function Developer() {

  const PhotoSubmit = createRef();
 
  const [isUploader,setIsUploader] = useState(false);
  const [photoList,setPhotoList]= useState([]);
  const [Feed, setFeed] = useState([]);
  const [Session, setSession] = useState({ State: '' });
  const [dragOver, setDragOver] = useState(false);
  const [previewMainUrl, setPreviewMainUrl] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);
  const [SendData, setSendData] = useState({
    startDate: '',
    endDate: '',
    topic: '',
    task: '',
    category: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSendData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const imageUploadBox = {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap', 
    maxWidth: '1400px',
    width:'100%',
    overflow:'hidden'
}

  const fileChangeHandler = event => {
      let files = Array.from(event.target.files); // FileList to Array
    
      Promise.all(files.map(file => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onloadend = event => {
            let image = new Image();
            image.src = event.target.result;
            image.onload = () => resolve({
              url: reader.result, width: image.width, height: image.height
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }))
      .then(newImages => {
        setPreviewMainUrl(newImages[0]);
        setPreviewUrl(prevImages => [...prevImages, ...newImages]);
        // if (newImages.length > 0) { // 추가된 이미지가 있다면
        //   setImageUrl(prevImages => [...prevImages, ...newImages]); // 첫 번째 이미지의 URL을 부모 상태에 저장
        // }
      }, error => console.error(error));
    };
    


  const onDragStart = (e, index) => {
      e.dataTransfer.setData("index", index);
  }

  const onDrop = (e, targetIndex) => {
      const draggedIndex = e.dataTransfer.getData("index");
      swapImage(draggedIndex, targetIndex)
  }

  const swapImage = (draggedIndex, targetIndex) => {
      setPreviewUrl(prevState => {
          let clonedState = [...prevState];
          let temp = clonedState[draggedIndex];
          clonedState[draggedIndex] = clonedState[targetIndex];
          clonedState[targetIndex] = temp;

          return clonedState;
      })
  }

  const deleteImage = (index) => {
      setPreviewUrl(prevState => prevState.filter((_, i) => i !== index));
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    
    const files = Array.from(e.dataTransfer.files); 

    Promise.all(files.map(file => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = event => {
          let image = new Image();
          image.src = event.target.result;
          image.onload = () => resolve({
            url: reader.result, width: image.width, height: image.height
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }))
    .then(newImages => {
      setPreviewMainUrl(newImages[0]);
      setPreviewUrl(prevImages => [...prevImages, ...newImages]);
      // if (newImages.length > 0) { // 추가된 이미지가 있다면
      //   setImageUrl(prevImages => [...prevImages, ...newImages]); // 첫 번째 이미지의 URL을 부모 상태에 저장
      // }
    }, error => console.error(error));


    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        // 이미지 파일 처리 로직 작성
        console.log('업로드된 이미지:', file);
        // 추가적인 작업 수행 (예: 서버로 전송, 미리보기 표시 등)
      } else {
        alert('이미지 파일만 업로드 가능합니다.');
      }
    }
  };
  
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => {
        if (msg.State === 'LOGIN') GetDevPhoto_ORG();
        setSession(msg)
      })
      .catch(err => alert(err + '오류'));
  }
  const DeletePortpolio=(key)=>{
    console.log('삭제하려는 항목 번호:'+key)
    fetch('/request/DeletePortpolio_developer', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({Key:key})
    })
      .then(() => {
        alert(key+'삭제완료');
        //Research();
    })
      .catch(err => alert('DeletePortpolio_developer' + err));
  }
  const clearData = () => {
    PhotoSubmit.current.disabled = false;
    document.getElementById("img").value = null;
    document.getElementById("TextInput").value = null;
    document.getElementById("thumbnailImg").src = null;
    document.getElementById("thumbnailImg").width = '0';
    document.getElementById("thumbnailImg").height = '0';
    GetDevPhoto_ORG();
  }
  const GetDevPhoto_ORG = () => {
    fetch('/request/GetDevPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setFeed(msg) })
      //    .then(msg=>alert(JSON.stringify(msg)))
      .catch(err => alert(err + '오류'));
  }

  
  /**
 * Base64 문자열을 Blob 객체로 변환합니다.
 * @param {string} base64Str - 변환할 base64 인코딩된 이미지 문자열.
 * @return {Blob} 변환된 Blob 객체.
 */
function base64ToBlob(base64Str) {
  // base64 인코딩된 문자열에서 데이터 부분만 추출 (콤마 이후의 부분)
  const base64ContentArray = base64Str.split(",");
  // MIME 타입 추출 (콤마 이전까지의 문자열 중 ':'와 ';' 사이의 문자열)
  const mimeType = base64ContentArray[0].match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

  // base64 문자열을 byte 배열로 변환
  const byteCharacters = atob(base64ContentArray[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  // Blob 객체 생성 및 반환
  return new Blob(byteArrays, {type: mimeType});
}
  const uploadImage = () => {
    PhotoSubmit.current.disabled = 'disabled';
    var formData = new FormData();
    previewUrl.forEach((photo, index) => {
      // base64 문자열을 Blob으로 변환
      const imageBlob = base64ToBlob(photo.url);
      // FormData에 추가
      formData.append('files', imageBlob, `-${index}.png`);
  });
  formData.append('userId',Session.UserId);
  formData.append('contents',SendData.contents);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/DeveloperFileUpload', {
      method: 'POST',
      mode: 'cors',
      credentials:'include',
      body: formData
    }).then((res) => {
      clearData();
    })
      .catch(err => 
        console.log(err));
  }
  useEffect(() => {
    CreateSession();

  }, []);
  return (
    <div>
    <div style={{ minHeight: '600px' }} className='PortfolioMasterPanel'>
      {Session.State != 'LOGIN' ? <div><h2>로그인 사용자만 이용가능합니다.</h2><MiniLogin getSession={CreateSession}/></div> :<>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className='WriteModeBox'>
        <input type="file" accept="image/*" onChange={fileChangeHandler} id="file" className="input-file" multiple  />
          <section style={{ display: 'flex', width: '100%' }}>
            <label
                      className="upload-label"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      htmlFor="file"
                      style={{
                        display: 'flex',               // Flexbox 레이아웃 사용
                        justifyContent: 'center',       // 수평 중앙 정렬
                        alignItems: 'center',           // 수직 중앙 정렬
                        flexShrink: 0,
                        borderColor: dragOver ? 'red': '#eee',
                        borderStyle:'solid', width: '300px' , height:'300px',
                        padding:'0px',
                        margin:'0px',
                        fontSize:'80pt',
                      }}
                      >+
              </label>
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%', paddingLeft: '10px',margin:'5px' }}>
              <form id='myForm' method="POST" encType="multipart/form-data" >
                <div>
                  <input placeholder="제목" style={{ width: '100%', height: '40px' ,borderRadius:'5px' }}></input>
                  <textarea placeholder='설명을 입력하세요' style={{ width: '100%', height: '155px', marginTop:'10px',resize:'none',borderRadius:'5px' }} id='TextInput' name="TextInput" value={SendData.contents} onChange={(ev) => setSendData({ ...SendData, contents: ev.target.value })}></textarea>
                </div>
                <hr />
                <div style={{display:'inline-flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                  <div>
                    <label>프로젝트 기간:</label>
                    <input
                      type="date"
                      id="start-date"
                      name="startDate"
                      value={SendData.startDate}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="end-date">~</label>
                    <input
                      type="date"
                      id="end-date"
                      name="endDate"
                      value={SendData.endDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <input
                    placeholder="주제"
                    style={{ width: '15%', height: '20px' }}
                    name="topic"
                    value={SendData.topic}
                    onChange={handleInputChange}
                  />
                  <input
                    placeholder="담당업무"
                    style={{ width: '15%', height: '20px' }}
                    name="task"
                    value={SendData.task}
                    onChange={handleInputChange}
                  />
                  <input
                    placeholder="카테고리"
                    style={{ width: '15%', height: '20px' }}
                    name="category"
                    value={SendData.category}
                    onChange={handleInputChange}
                  />
                </div>
                <hr />
              </form>
              <button ref={PhotoSubmit} onClick={() => uploadImage()}>게시</button>
            </div>
          </section>
      
        </div>
        <div style={imageUploadBox}>
          <main style={{display:'flex'}}>
            <input type="file" accept="image/*" onChange={fileChangeHandler} id="file" className="input-file" multiple  />
 
              <section style={{display:'flex' ,padding:'5px',width:'100%',justifyContent:'space-between'}}>
              {
        Array.from({ length: 5 }, (_, index) => (
        <div className='imgLoader' style={{flexShrink: 0,flexWrap:'wrap',width: '200px' , height:'200px'}} key={index} draggable onDragStart={(e) => onDragStart(e, index)} onDrop={(e) => onDrop(e, index)}
            onDragOver={(e) => { e.preventDefault(); }}>
            {previewUrl && previewUrl[index] ? (
                <img
                    src={previewUrl[index].url}
                    style={{
                        width: previewUrl[index].width > previewUrl[index].height ? '100%' : 'auto',
                        height: previewUrl[index].height >= previewUrl[index].width ? '100%' : 'auto'
                    }}
                />
            ) : <label style={{fontSize:'40px'}}>+</label>}
            <button className='delBtn' onClick={() => deleteImage(index)}>×</button>
        </div>
    ))
}
            </section>
        </main>
        </div>
          <hr width="100%"></hr>
          </>
        }
        {Feed.length === 0 ? '' : Feed.map((Data, i) => (
            <section style={{ width:'100%', background:'#fff' , borderRadius:'10px',margin:'10px' ,alignItems:'center'}}>
              <article style={{display:'flex',flexDirection:'row'}}>
              <img style={{padding:'10px' ,margin:'10px'}}  width="180" height="180" src={Data.pix_loadPath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/DeveloperFile/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
              <main style={{width:'70%'}}>
              <div style={{padding:'10px' ,margin:'10px'}}>프로젝트명 : </div>
              <div style={{padding:'10px' ,margin:'10px'}}>담당업무 : </div>
              <div style={{padding:'10px' ,margin:'10px'}}>작업기간 : </div>
              </main>
              <aside>
                <figure><button>편집</button></figure>
                <figure><button onClick={()=>DeletePortpolio(Data.pix_num)}>삭제</button></figure>
              </aside>
              </article>
          </section>
        ))}
      </div>
    </div>
  );
}



export default Developer;
