import React, { useState } from 'react';
import './style.css';

const ImgInput = React.forwardRef(({ setImageUrl,setAlign,setWidth,setHeight }, ref) => {
    const [dragOver, setDragOver] = useState(false);
    const [previewUrl, setPreviewUrl] = useState([]);
    
    const imageUploadBox = {
      justifyContent: setAlign ? setAlign: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap', 
      maxWidth: '1400px',
      width:'100%',
      overflow:'hidden'
  }

    const fileChangeHandler = event => {
        let files = Array.from(event.target.files); // FileList to Array
      
        Promise.all(files.map(file => {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = event => {
              let image = new Image();
              image.src = event.target.result;
              image.onload = () => resolve({
                url: reader.result, width: image.width, height: image.height
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        }))
        .then(newImages => {
          setPreviewUrl(prevImages => [...prevImages, ...newImages]);
          if (newImages.length > 0) { // 추가된 이미지가 있다면
            setImageUrl(prevImages => [...prevImages, ...newImages]); // 첫 번째 이미지의 URL을 부모 상태에 저장
          }
        }, error => console.error(error));
      };
      


    const onDragStart = (e, index) => {
        e.dataTransfer.setData("index", index);
    }

    const onDrop = (e, targetIndex) => {
        const draggedIndex = e.dataTransfer.getData("index");
        swapImage(draggedIndex, targetIndex)
    }

    const swapImage = (draggedIndex, targetIndex) => {
        setPreviewUrl(prevState => {
            let clonedState = [...prevState];
            let temp = clonedState[draggedIndex];
            clonedState[draggedIndex] = clonedState[targetIndex];
            clonedState[targetIndex] = temp;

            return clonedState;
        })
    }

    const deleteImage = (index) => {
        setPreviewUrl(prevState => prevState.filter((_, i) => i !== index));
    }

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      
      const files = Array.from(e.dataTransfer.files); 

      Promise.all(files.map(file => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onloadend = event => {
            let image = new Image();
            image.src = event.target.result;
            image.onload = () => resolve({
              url: reader.result, width: image.width, height: image.height
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }))
      .then(newImages => {
        setPreviewUrl(prevImages => [...prevImages, ...newImages]);
        if (newImages.length > 0) { // 추가된 이미지가 있다면
          setImageUrl(prevImages => [...prevImages, ...newImages]); // 첫 번째 이미지의 URL을 부모 상태에 저장
        }
      }, error => console.error(error));


      if (files.length > 0) {
        const file = files[0];
        if (file.type.startsWith('image/')) {
          // 이미지 파일 처리 로직 작성
          console.log('업로드된 이미지:', file);
          // 추가적인 작업 수행 (예: 서버로 전송, 미리보기 표시 등)
        } else {
          alert('이미지 파일만 업로드 가능합니다.');
        }
      }
    };

    return (
        <div style={imageUploadBox}>
          <main style={{display:'flex'}}>
            <input type="file" accept="image/*" onChange={fileChangeHandler} id="file" className="input-file" multiple ref={ref}  />
              <label
                      className="upload-label"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      htmlFor="file"
                      style={{
                        flexShrink: 0,
                        borderColor: dragOver ? 'red': '#eee',
                        borderStyle:'solid',width: setWidth? setWidth:'200px' , height:setHeight?setHeight:'200px'
                        
                      }}
                      >+
              </label>
              <section style={{display:'flex'}}>
            {previewUrl &&
                previewUrl.map((imageData, index) => (
                    <div className='imgLoader' style={{flexShrink: 0,flexWrap:'nowrap',width: setWidth? setWidth:'200px' , height:setHeight?setHeight:'200px'}} key={index} draggable onDragStart={(e) => onDragStart(e, index)} onDrop={(e) => onDrop(e, index)}
                        onDragOver={(e) => { e.preventDefault(); }}>
                        <img
                            key={index}
                            src={imageData.url}
                            style={{
                                width: imageData.width > imageData.height ? '100%' : 'auto',
                                height: imageData.height >= imageData.width ? '100%' : 'auto'
                            }}
                        />
                        <button className='delBtn' onClick={() => deleteImage(index)}>×</button>
                    </div>
                ))
            }
            </section>
        </main>
        </div>
    );
});
 export default ImgInput;