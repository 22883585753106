import React, { createRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import * as action from './HelpAction'

import Select from '@material-ui/core/Select';



function Help(props) {
  const Bug = createRef();
  const Inquiry = createRef();
  const Suggestions = createRef();

  const MenuArray = [Bug, Inquiry, Suggestions];
  const [Category, setCategory] = useState(['ALL', 'NoData']);
  const [SelectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    action.ActiveCheck(Inquiry, MenuArray, props, setSelectedCategory, setCategory);
  }, []);


  return (
    <div className='Wrapper'>
      <div className='TabPage'>
        <ul className="SubNavCategory" style={{ borderStyle: 'solid', borderBottomStyle: 'none', borderColor: 'silver', borderWidth: '1px' }} >
          <li><a id='Inquiry' className="" ref={Inquiry} onClick={() => action.ActiveCheck(Inquiry, MenuArray, props, setSelectedCategory, setCategory)}> 문의사항</a></li>
          <li><a id='Bug' className="" ref={Bug} onClick={() => action.ActiveCheck(Bug, MenuArray, props, setSelectedCategory, setCategory)}> 버그리포트</a></li>
          <li><a id='Suggestions' className="" ref={Suggestions} onClick={() => action.ActiveCheck(Suggestions, MenuArray, props, setSelectedCategory, setCategory)}> 건의함</a></li>
          <li style={{ float: 'right', padding: '9px 14px' }}><Select style={{ width: "150px" }} value={SelectedCategory} defaultValue={"ALL"} type='text' onChange={SelectedCategory}>
            <option value='ALL'>ALL</option>
            {Category.map((Data, i) => (<option key={i} value={Data.notice_category}>{Data.notice_category}</option>))}
          </Select></li>
        </ul>
        <div style={{ borderWidth: '1px', borderStyle: 'solid', borderBottomStyle: 'none', borderColor: 'silver' }} id='Board_View' />
      </div>
    </div>
  )
}
export default Help;