import React, { createRef, useState } from 'react';
import { BrowserRouter as Router, Route,Link} from 'react-router-dom';
import Logo from '../../../image/design/Logo.png';
import company from '../../../image/design/company.jpg';
import product from '../../../image/design/product.jpg';
import workroom from '../../../image/design/workroom.jpg';
import study from '../../../image/design/community.jpg';
import download from '../../../image/design/download.jpg';
import helpdesk from '../../../image/design/helpdesk.jpg';
import companyAct from '../../../image/design/companyAct.jpg';
import productAct from '../../../image/design/productAct.jpg';
import workroomAct from '../../../image/design/workroomAct.jpg';
import studyAct from '../../../image/design/communityAct.jpg';
import downloadAct from '../../../image/design/downloadAct.jpg';
import helpdeskAct from '../../../image/design/helpdeskAct.jpg';
import './style.css';

function Metro() {
  const [logoSrc,setLogoSrc] = useState(Logo);
  const [companySrc,setCompanySrc] = useState(company);
  const [productSrc,setProductSrc] = useState(product);
  const [workroomSrc,setWorkroomSrc] = useState(workroom);
  const [studySrc,setStudySrc] = useState(study);
  const [downloadSrc,setDownloadSrc] = useState(download);
  const [helpdeskSrc,setHelpdeskSrc] = useState(helpdesk);
  const HomeMenu = createRef();
  const CompanyMenu = createRef();
  const ProductMenu = createRef();
  const WorkRoomMenu  = createRef();
  const DownloadMenu = createRef();
  const StudyMenu = createRef();
  const HelpMenu  = createRef();

  const ActiveState = [
    { IsActive:false, Name:'Home',Org : Logo , Act : Logo ,Ref:HomeMenu,Set:setLogoSrc},
    { IsActive:false, Name:'company',Org : company , Act : companyAct,Ref:CompanyMenu,Set:setCompanySrc},
    { IsActive:false, Name:'product',Org : product , Act : productAct,Ref:ProductMenu,Set:setProductSrc},
    { IsActive:false, Name:'download',Org : download , Act : downloadAct,Ref:DownloadMenu,Set:setDownloadSrc},
    { IsActive:false, Name:'workroom',Org : workroom , Act : workroomAct,Ref:WorkRoomMenu,Set:setWorkroomSrc},
    { IsActive:false, Name:'study',Org : study , Act : studyAct,Ref:StudyMenu,Set:setStudySrc},
    { IsActive:false, Name:'helpdesk',Org : helpdesk , Act : helpdeskAct,Ref:HelpMenu,Set:setHelpdeskSrc},
  ]

  function ActiveCheck(e,set,org,act){
    ActiveState.map((Item)=>{
      if(Item.Name !== e){
        if(Item.Ref.current.className === 'active'){
          Item.Ref.current.className = '';
          Item.Set(Item.Org);
          Item.IsActive=false;
        }
      }
      else{
        Item.Ref.current.className = 'active';
        Item.Set(Item.Act);
        Item.IsActive=true;
      }
    })
  }
    return (
      <div style={{display:'flex', width:'1200px',height:'135px'}}>
        <div id='LogoArea' style ={{display:"inline-flex",width:'200px' ,marginTop:'14px',marginBottom:'12px',marginRight:'310px',flexDirection:"column",justifyContent:'center'}}>
          <Link ref={HomeMenu}  onClick={(e)=>ActiveCheck(e,setLogoSrc,Logo,Logo)} style={{textDecoration:'none',color:'black'}} to='/' id="HOME"><img   src={Logo}/></Link>
        </div>
        <ul className="Nav">
          <li><Link ref={CompanyMenu} onClick={(e)=>ActiveCheck('company',setCompanySrc,company,companyAct)} to='/Company'>강사소개</Link></li>
           <li><Link ref={ProductMenu}  onClick={(e)=>ActiveCheck('product',setProductSrc,product,productAct)} to='/Product'>수업과정</Link></li>
          <li><Link ref={DownloadMenu}  onClick={(e)=>ActiveCheck('download',setDownloadSrc,download,downloadAct)} to='/Download'>자료실</Link></li>
          <li><Link ref={WorkRoomMenu} onClick={(e)=>ActiveCheck('workroom',setWorkroomSrc,workroom,workroomAct)} to='/WorkRoom' >작업실</Link></li> 
          <li><Link ref={StudyMenu} onClick={(e)=>ActiveCheck('study',setStudySrc,study,studyAct)} to='/Study' >강의실</Link></li>
          <li><Link ref={HelpMenu} onClick={(e)=>ActiveCheck('helpdesk',setHelpdeskSrc,helpdesk,helpdeskAct)} to='/Help'>고객지원</Link></li>
        </ul>
       </div>
    );
  }
  
export default Metro;



