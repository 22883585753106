import {createContext, useReducer} from 'react';//CONTEXT HOC

export const Context = createContext();
const initialState={
    LOT_GRID_ROWS:""
};

//리듀서
const reducer = (state,action) =>{
    switch(action.type) {
        case 'SET_LOT_GRID_ROWS' : return {...state,LOT_GRID_ROWS:action.value};
    default:
        throw new Error("MSG");
    }
}

//CONTEXT HOC
export const withContext =  WrappedComponent => (props) => {
    const [state,dispatch] = useReducer(reducer,initialState);
    return (
    <Context.Provider value= {{state,dispatch}}>
        <WrappedComponent {...props}/>
    </Context.Provider>
    )};

