
import React, { useState,useEffect } from "react";
import RoomList from '../RoomList';

function Study(){
    return (
    <>
        <RoomList/>
    </>)
}
export default Study;