
import Timeline from "./Timeline";
import MyPicture from "./MyPicture";
import ReactDOM from 'react-dom';

export const InitializeScreen = (props, data, menuArray) => {
    if (props.type === 'time') {
        // 아직 구현예정 없음
    } else if (props.type === 'my') {
        // 아직 구현예정 없음
    } else {
        ReactDOM.render(<Timeline Selecter={(e) => activeFind(e, menuArray)} LinkAction={true} />, document.getElementById('Board_View'));
    }
}
export const getSession = async (msg, setSession) => {
    let MySession = msg;
    await setSession(MySession);
}
export const CreateSession = (setSession) => {
    fetch('/request/CreateSession', { credentials: 'include' })
        .then(res => res.json())
        .then((msg) => { getSession(msg, setSession) })
        .catch(err => alert(err + '오류'))
}

export const activeFind = (selectedTab, MenuArray) => {
    var time = MenuArray[0].current;
    var my = MenuArray[1].current;

    if (selectedTab === 'time') {
        activeCheck(time, MenuArray);
    } else if (selectedTab === 'my') {
        activeCheck(my, MenuArray);
    }
}

/// 선택 tab에 따른 화면 이동
export const activeCheck = (menu, menuArray) => {
    try {

        menuArray.map((item, index) => {
            if (item.current.className === 'tabActive') {
                item.current.className = '';
            }
        })
        if (menu.current.className === 'tabActive') {
            menu.current.className = '';
        } else {
            menu.current.className = 'tabActive';
            if (menu.current.id === 'timeline') {
                ReactDOM.render(<Timeline selector={(e) => activeFind(e, menuArray)} linkAction={true} />, document.getElementById('Board_View'));
            } else if (menu.current.id === 'myPicture') {
                ReactDOM.render(<MyPicture selector={(e) => activeFind(e, menuArray)} linkAction={true} />, document.getElementById('Board_View'));
            }
        }
    } catch (err) {
    }
}