import React from 'react';
import Board from '../../Common/Component/Board';
import ChatRoom from '../../Common/Component/ChatRoom/ChatRoom';
import TabContorl from '../WorkRoom/MyWork/Tab';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route,Link} from 'react-router-dom';
import chatroom from '../../image/design/CommunityMenu_chatroom.jpg';
import Freeboard from '../../image/design/CommunityMenu_freeboard.jpg';
import Camera from '../../image/design/CommunityMenu_photo.jpg';
import Camcorder from '../../image/design/CommunityMenu_video.jpg';
import RemoteSupport from '../../image/design/CommunityMenu_remote.jpg';
import Cloud from '../../image/design/CommunityMenu_cloud.jpg';
import Kakao from '../../image/design/CommunityMenu_kakao.jpg';
import Blog from '../../image/design/CommunityMenu_blog.jpg';

import '../style.css';
// ReactDOM.render(<ChatRoom />, document.querySelector('#root'));

function NOTICE() {

  return (
    <div className='Wrapper'>
        <div className='NoticeMasterPanel'>
          <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',textAlign:'left'}}>
            <h2>앤츠네스트 커뮤니티</h2>
            <h3> 앤츠네스트를 함께 만들어가는 공간입니다.</h3>
          </div>
            <TabContorl SelectedTab={'Developer'}/> 
         <div className='CommunityBox'><Link to='/Join'><img src={chatroom}/></Link></div>
          <div className='CommunityBox'><Link to='/Board'><img src={Freeboard}/></Link></div>
          <div className='CommunityBox'><Link to='/Photo'><img src={Camera}/></Link></div>
          <div className='CommunityBox'><a href="http://story.kakao.com/ch/ants"><img src={Kakao}/></a></div>
          <div className='CommunityBox'><Link to='/Video'><img src={Camcorder}/></Link></div>
          <div className='CommunityBox'><Link to='/Remote'><img src={RemoteSupport}/></Link></div>
          <div className='CommunityBox'><Link to='/Cloud'><img src={Cloud}/></Link></div>
          <div className='CommunityBox'><a href="https://blog.naver.com/yhs1103korea"><img src={Blog}/></a></div>
          
        </div>
    </div>
  );
}

export default NOTICE;
