import React, { useState, useEffect } from 'react';
import './style.css';

function ThumbnailViewer() {
  const [Feed, setFeed] = useState([{}]);
  
  const getFeed = () => {
    fetch('/request/GetFeedPhoto', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setFeed(msg) })
      //.then(msg=>alert(JSON.stringify(msg)))
      .catch(err => alert(err + '오류'));
  }

  useEffect(() => {
    getFeed();
  }, []);

  return (
    <span>
      <div style={{ textAlign: 'center' }} className='ThumbnailViewer'>
        <div>
          <div id='NewPhotoThumbnail' className='thumbnail'>
            <img className='thumbnail-img' src={Feed[0] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT + '/antsnest/App/' + Feed[0].pix_loadpath} />
            <div className='thumbnail-label'>
              <label className='thumbnail-text'>{(Feed[0] == undefined || Feed[0].pix_contents == "") ? "-" : Feed[0].pix_contents}</label>
            </div>
          </div>
          <div id='NewPhotoThumbnail' className='thumbnail'>
            <img className='thumbnail-img' src={Feed[1] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT + '/antsnest/App/' + Feed[1].pix_loadpath} />
            <div className='thumbnail-label'>
              <label className='thumbnail-text'>{(Feed[1] == undefined || Feed[1].pix_contents == "") ? "-" : Feed[1].pix_contents}</label>
            </div>
          </div>
        </div>
        <div>
          <div id='NewPhotoThumbnail' className='thumbnail'>
            <img className='thumbnail-img' src={Feed[3] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT + '/antsnest/App/' + Feed[3].pix_loadpath} />
            <div className='thumbnail-label'>
              <label className='thumbnail-text'>{(Feed[3] == undefined || Feed[3].pix_contents == "") ? "-" : Feed[3].pix_contents}</label>
            </div>
          </div>
          <div id='NewPhotoThumbnail' className='thumbnail'>
            <img className='thumbnail-img' src={Feed[4] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT + '/antsnest/App/' + Feed[4].pix_loadpath} />
            <div className='thumbnail-label'>
              <label className='thumbnail-text'>{(Feed[4] == undefined || Feed[4].pix_contents == "") ? "-" : Feed[4].pix_contents}</label>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}

export default ThumbnailViewer;