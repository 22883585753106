import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import MyLogo from '../../image/MyLogo.png';
import Board from '../../Common/Component/Board/Mobile';
import FreeBoard from '../../Common/Component/Board/FreeBoard/Mobile';
import NewsBoard from '../../Common/Component/Board/NewsBoard/Mobile';
import QaBoard from '../../Common/Component/Board/QaBoard/Mobile';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Notice from '../Community/mobile';
import '../M.style.css';

function MobileTimeLine() {
  const LIMIT = 12;
  const [Feed, setFeed] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const getFeed = () => {
    fetch('/request/GetAllFeedPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then(msg => setFeed(Feed.concat(msg.slice(offset, offset + LIMIT))))
      .then(() => {
        setOffset(offset + LIMIT);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  useEffect(() => {
    getFeed();
  }, []);
  let TabPageStyle = {
    display: 'flex',
    flexWrap: "wrap",
  };
  let SubNavStyle = {
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '0',
    flexWrap: "wrap",
    backgroundColor: '#fff',
  };
  let ListStyle = {
    display: 'flex',
    listStyleType: 'none',
    margin: '15px',
  };
  return (
    <div id='WrapperBox' style={{ height: 'auto' }} className='Wrapper'>
      <div id='MobilelTabPage' style={TabPageStyle}>
        <ul id='MobileTabNavigater' style={SubNavStyle} >
          <li style={ListStyle}><Link to="/" className="active"> 홈</Link></li>
          <li style={ListStyle}><Link to="/TimeLine" className=""> 뉴스피드</Link></li>
          <li style={ListStyle}><Link to="/Photo" className="active"> 타임라인</Link></li>
        </ul>
      </div>
      <div className='ThumbnailViewer' style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div>
          {
            Feed.map((Data, i) =>
            (Data.pix_mimetype !== undefined && Data.pix_mimetype.toString().indexOf("video/") !== -1 ?
              <video id='NewPhotoThumbnail' width='145px' height='145px'>
                <source src={Data.pix_loadPath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
              </video>
              :
              <img id='NewPhotoThumbnail' width='145px' height='145px' src={Feed[0] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadPath} />
            ))


          }<br />
          <button style={{ marginBottom: '40px' }} onClick={() => getFeed()}>더보기</button>
        </div>
      </div>
    </div>
  );
}

export default MobileTimeLine;
