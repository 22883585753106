import { Alarm } from '@material-ui/icons';
import React, { createRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FreeBoard from './FreeBoard';
import NewsBoard from './NewsBoard';
import QaBoard from './QaBoard';
import DeveloperNoteBoard from './DeveloperNoteBoard';

export const InitializeScreen = (props, Data, MenuArray) => {
    var News = MenuArray[0];
    var Free = MenuArray[1];
    var QA = MenuArray[2];
    var DeveloperNote = MenuArray[3];

    if (props.Type === 'FreeBoard') {
        if (Data.NUM == 0)
            ReactDOM.render(<FreeBoard Selecter={(e) => ActiveFind(Free, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
        else
            ReactDOM.render(<FreeBoard Data={Data} Selecter={(e) => ActiveFind(Free, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
    }
    else if (props.Type === 'NewsBoard') {
        if (Data.NUM == 0)
            ReactDOM.render(<NewsBoard Selecter={(e) => ActiveFind(News, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
        else
            ReactDOM.render(<NewsBoard Data={Data} Selecter={(e) => ActiveFind(News, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
    }
    else if (props.Type === 'QaBoard') {
        if (Data.NUM == 0)
            ReactDOM.render(<QaBoard Selecter={(e) => ActiveFind(QA, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
        else
            ReactDOM.render(<QaBoard Data={Data} Selecter={(e) => ActiveFind(QA, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
    }
    else if (props.Type === 'DeveloperNoteBoard') {
        if (Data.NUM == 0)
            ReactDOM.render(<DeveloperNoteBoard Selecter={(e) => ActiveFind(DeveloperNote, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
        else
            ReactDOM.render(<DeveloperNoteBoard Data={Data} Selecter={(e) => ActiveFind(DeveloperNote, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
    }
    else
        ReactDOM.render(<FreeBoard Selecter={(e) => ActiveFind(DeveloperNote, MenuArray)} LinkAction={true} />, document.getElementById('Board_View'));
}

export const ActiveFind = (Boards, MenuArray) => {
    ActiveCheck(Boards, MenuArray);
}

export const ActiveCheck = (Menu, MenuArray) => {
    try {
        MenuArray.map((Item, Index) => {
            if (Item.current.className === 'TabActive')
                Item.current.className = '';
        })
        if (Menu.current.className === 'TabActive')
            Menu.current.className = '';
        else {
            Menu.current.className = 'TabActive';
            if (Menu.current.id === 'NewsBoard')
                ReactDOM.render(<NewsBoard Selecter={ActiveFind} LinkAction={false} />, document.getElementById('Board_View'));
            else if (Menu.current.id === 'FreeBoard')
                ReactDOM.render(<FreeBoard Selecter={ActiveFind} LinkAction={false} />, document.getElementById('Board_View'));
            else if (Menu.current.id === 'QaBoard')
                ReactDOM.render(<QaBoard Selecter={ActiveFind} LinkAction={false} />, document.getElementById('Board_View'));
            else if (Menu.current.id === 'DeveloperNoteBoard')
                ReactDOM.render(<DeveloperNoteBoard Selecter={ActiveFind} LinkAction={false} />, document.getElementById('Board_View'));
        }
    }
    catch (err) {

    }
}