import React, { createRef, useState, useEffect } from 'react';
import './style.css'

function ThumbnailViewer() {
  const [Feed, setFeed] = useState([{}]);
  const getFeed = () => {
    fetch('/request/GetFeedPhoto', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setFeed(msg) })
      //.then(msg=>alert(JSON.stringify(msg)))
      .catch(err => alert(err + '오류'));
  }
  useEffect(() => {
    getFeed();
  }, []);
  var NewPhotoThumbnail = {
    marginBottom: '15px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '10px',
    borderColor: '#f1f1f1',
    boxShadow: '2px 2px 2px 2px #f1f1f1',
    position: 'relative',
    width: '160px',
    height: '160px'
  }
  return (
    <span style={{ marginTop: '40px', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignContent: 'space-evenly',
          flexWrap: 'nowrap',
          height: '350px',
          width: '280px;'
        }}>
        <div>
          <div style={NewPhotoThumbnail}>
            <img style={{ position: 'relative', borderRadius: '10px', objectFit:'cover' }} width='160px' height='160px' src={Feed[0] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Feed[0].pix_loadPath} />
            <div style={{ lineHeight: '30px', position: 'absolute', backgroundColor: 'black', width: '100%', bottom: '-2px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', opacity: '0.8' }}>
              {/* <label style={{fontSize:'10pt',color:'white'}}>썸네일1</label> */}
              <label style={{ fontSize: '10pt', color: 'white' }}>{(Feed[0] == undefined || Feed[0].pix_contents == "") ? "-" : Feed[0].pix_contents}</label>
            </div>
          </div>

          <div style={NewPhotoThumbnail}>
            <img style={{ position: 'relative', borderRadius: '10px', objectFit:'cover' }} width='160px' height='160px' src={Feed[1] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Feed[1].pix_loadPath} />
            <div style={{ lineHeight: '30px', position: 'absolute', backgroundColor: 'black', width: '100%', bottom: '-2px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', opacity: '0.8' }}>
              {/* <label style={{fontSize:'10pt',color:'white'}}>썸네일3</label> */}
              <label style={{ fontSize: '10pt', color: 'white' }}>{(Feed[1] == undefined || Feed[1].pix_contents == "") ? "-" : Feed[1].pix_contents}</label>
            </div>
          </div>

        </div>
        <div>
          <div style={NewPhotoThumbnail}>
            <img style={{ position: 'relative', borderRadius: '10px', objectFit:'cover' }} width='160px' height='160px' src={Feed[3] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Feed[3].pix_loadPath} />
            <div style={{ lineHeight: '30px', position: 'absolute', backgroundColor: 'black', width: '100%', bottom: '-2px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', opacity: '0.8' }}>
              {/* <label style={{fontSize:'10pt',color:'white'}}>썸네일2</label> */}
              <label style={{ fontSize: '10pt', color: 'white' }}>{(Feed[3] == undefined || Feed[3].pix_contents == "") ? "-" : Feed[3].pix_contents}</label>
            </div>
          </div>
          <div style={NewPhotoThumbnail}>
            <img style={{ position: 'relative', borderRadius: '10px', objectFit:'cover' }} width='160px' height='160px' src={Feed[4] == undefined ? 'NoImage.png' : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Feed[4].pix_loadPath} />
            <div style={{ lineHeight: '30px', position: 'absolute', backgroundColor: 'black', width: '100%', bottom: '-2px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', opacity: '0.8' }}>
              {/* <label style={{fontSize:'10pt',color:'white'}}>썸네일4</label> */}
              <label style={{ fontSize: '10pt', color: 'white' }}>{(Feed[4] == undefined || Feed[4].pix_contents == "") ? "-" : Feed[4].pix_contents}</label>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}

export default ThumbnailViewer;