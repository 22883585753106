import * as service from './HomeViewService.js';

export const InitializeScreen = (view) => {
  getSession(view);
  SelectFree(view);
  SelectNews(view);
  SelectQA(view);
}

export const getSession = (view) => { service.SessionCheck(view); }

export const SelectNews = (view) => { service.getNewsBoardData(view); }

export const SelectQA = (view) => { service.getQAboardData(view); }

const SelectFree = (view) => {
  service.getFreeboardData(view);

}