import React, { createRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import * as action from './PhotoAction';

import './style.css';

function Photo(props) {
    const ROUNGE = createRef(); // 타임라인 메뉴
    const PHOTO = createRef(); // 내사진 메뉴
    const menuArray = [ROUNGE, PHOTO]; // 메뉴 배열
    const [Session, setSession] = useState({});

    useEffect(() => {
        action.CreateSession(setSession);
        var data = { "num": props.match.params.num, "notice_name": "", "notice_text": "" }
        action.InitializeScreen(props, data, menuArray);
    }, []);
    const ActiveCheck = (Menu, MenuArray) => {
        try {
          MenuArray.map((Item, Index) => {
            if (Item.current.className === 'TabActive')
              Item.current.className = '';
          })
          if (Menu.current.className === 'TabActive')
            Menu.current.className = '';
          else
            Menu.current.className = 'TabActive';
        }
        catch (err) {
    
        }
      }
    return (
        <div className='Wrapper'>
            <div className='TabControl'>
                <div className='TabPageBig'>
                <ul style={{display:'flex',borderBottomLeftRadius:'15px',borderBottomRightRadius:'15px',paddingInlineStart:'0px',margin:'0px'}}>
                    <li><Link to="/"  className=""  ref={ROUNGE} id='ROUNGE' onClick={() => ActiveCheck(ROUNGE, menuArray)}> 라운지 </Link></li>
                    <li><Link to='/PHOTO'  className="TabActive" ref={PHOTO} id="PHOTO" onClick={() => ActiveCheck(PHOTO, menuArray)}>사진첩</Link></li>
                </ul>
                    <div id='Board_View' style={{ display: 'flex', justifyContent: 'center' }} />
                </div>
            </div>
        </div>
    );
}

export default Photo;