import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import React, { createRef, useState, useEffect } from 'react';
import ToDoList from '../../../Common/Component/TodoList'
import MiniLogin from '../../../Common/Component/LoginBox/MiniLogin';
import ReactDOM from 'react-dom';
import GanttChart from '../../../Common/Component/GanttChart';

function MySchedule() {
  const [Session,setSession] = useState({State:'LOGOUT'});
  const WorkCalendarRef = createRef();
  const WorkRoomRef = createRef();
  const PortfolioRef = createRef();

  const CreateSession = async () => {
    let SessionData = await new Promise(function (resolve) {
        fetch('/request/CreateSession', { credentials: 'include' })
            .then(res => res.json())
            .then(Param => resolve(Param))
            .catch(err => alert(err + '오류'))
    });
    setSession(SessionData);
}
  useEffect(() => {
    CreateSession();
  }, []);

  return (
    <div className='Wrapper'>
      <h1>앤츠네스트 작업실</h1>
      <div className='TabControl'>
        <div className='TabPage'>
          <ul className="SubNav">
            <li style={{ backgroundColor: 'white', borderColor: 'silver', borderStyle: 'solid', borderWidth: '1px', borderBottomStyle: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><Link to='/WorkRoom' id='WorkRoom' className="" ref={WorkRoomRef}> 작업관리</Link></li>
            <li style={{ backgroundColor: 'white', borderColor: 'silver', borderStyle: 'solid', borderWidth: '1px', borderBottomStyle: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><Link to='/Portfolio' id='Portfolio' className="" ref={PortfolioRef}> 포트폴리오 </Link></li>
            <li style={{ backgroundColor: 'white', borderColor: 'silver', borderStyle: 'solid', borderWidth: '1px', borderBottomStyle: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><Link to='/WorkCalendar' id='WorkCalendar' className="TabActive" ref={WorkCalendarRef}> 일정관리</Link></li>
          </ul>
          <div style={{ borderWidth: '1px', borderStyle: 'solid', borderBottomStyle: 'none', borderColor: 'silver' }} id='Board_View'>
            {(Session?.State===undefined || Session?.State =='LOGOUT') &&
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>로그인 사용자만 이용가능합니다.</h2>
                <MiniLogin getSession={CreateSession} />
              </div>
            }
            {Session !== undefined && Session?.State === 'LOGIN' &&
              <TabSection Session={Session}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySchedule;

export const TabSection = ({Session}) => {
  const [activeTab, setActiveTab] = useState('ganttChart'); // 활성 탭 상태 관리
  // 탭 변경 핸들러
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(()=>{

  },[Session])
  return (
    <section>
      <div className="tabs">
        <button style={{width:'50%'}} onClick={() => handleTabChange('toDoList')}>투두 리스트</button>
        <button style={{width:'50%'}} onClick={() => handleTabChange('ganttChart')}>간트 차트</button>
      </div>
      {activeTab === 'ganttChart' && <GanttChart Session={Session}/>}
      {activeTab === 'toDoList' && <ToDoList Selecter={'Developer'} LinkAction={true} />}
    </section>
  );
};

