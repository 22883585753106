import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../../../style.css';

function NewsViewer(props) {
    return (
        <div className='게시판_컨테이너' style={{ marginRight: '36px' }}>
            <span style={{ display: 'flex', justifyContent:'space-between', height: '40px' }}>
                <Link className='HeaderLink' to={'/NewsBoard/' + 0}>
                    <img src={props.title}></img>
                </Link>
                <Link className='HeaderLink' to={'/NewsBoard/' + 0} style={{fontSize:'16px', color:'#999'}}>
                    더보기
                </Link>
            </span>
            <hr color='#eaeaea' />
            <table className='공지사항_게시판'>
                <thead>

                </thead>
                <tbody>
                    {props.NewsData.map((Data, i) =>
                    (
                        <tr style={{ height: '35px' }} key={i}>
                            <td><Link className='NewsLink' to={'/NewsBoard/' + Data.NUM}><label style={{ fontSize: '13pt', fontFamily: 'Pretendard-Regular' }}>{Data.notice_title}</label></Link></td>
                            <td><div align='center'><label style={{ fontSize: '13pt', fontFamily: 'Pretendard-Regular' }}>{Data.notice_name}</label></div></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default NewsViewer