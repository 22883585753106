import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
const VideoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: ${(props) => props.width}px;
aspect-ratio: 16 / 9;
`;
const PeerVideoDiv = styled.video`
border: 1px solid red;
width: ${(props) => props.width}px;
border: 1px solid red;
border-radius: 5px;
position: initial;
display: flex;
flex: 1 1 auto;
max-width: 100%;
width: 100%;  // 비디오 너비를 100%로 설정하여 부모 요소에 맞춤
`;
const ControlButtons = styled.div`
display: flex;
justify-content: space-evenly;
`;
const Button = styled.button`
width: 25%;
height: 40px;
border-radius: 5px;
border: 1px solid #ddd;
background-color: #fff;
color: #333;
cursor: pointer;

&:hover {
  background-color: #f7f7f7;
}
`;
const Video = ({ peer }) => {
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isWebcamOn, setIsWebcamOn] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(false);

  const handleWebcamShare = () => {
    setIsWebcamOn(current => !current);
    if (!isWebcamOn) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: isAudioMuted ? false : true }).then((stream) => {
          ref.current.srcObject = stream;
        }).catch(err => {
            console.error("Error: ", err);
        });
    } else {
        // 웹캠을 끄는 로직, 스트림을 끄거나 UI 업데이트
    }
};
const handleAudioMute = () => {
  setIsAudioMuted(current => !current);
  ref.current.srcObject.getAudioTracks().forEach(track => {
      track.enabled = !track.enabled;
  });
};
  const handleScreenShare = () => {
    if (!isScreenSharing) {
        navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
            // 본인의 비디오 스트림을 화면 공유 스트림으로 전환
            ref.current.srcObject = stream;
            setIsScreenSharing(true);
        }).catch(err => {
            console.error("Error: ", err);
        });
    } else {
        // 화면 공유를 중지하고 웹캠 스트림으로 전환(웹캠이 켜져있다면)
        if(isWebcamOn) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
              ref.current.srcObject = stream;
                setIsScreenSharing(false);
            }).catch(err => {
                console.error("Error: ", err);
            });
        } else {
            setIsScreenSharing(false);
        }
    }
};
  const ScreenShareButton = ({handleScreenShare}) => (
    <Button onClick={handleScreenShare}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="2" y="2" width="20" height="20" rx="5" />
        <path d="M16 10h4" />
        <path d="M12 14L8 18" />
        <path d="M16 18L8 14" />
      </svg>
      화면 공유
    </Button>
  );
  
  const WebcamShareButton = (handleWebcamShare) => (
    <Button onClick={handleWebcamShare}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M12 7L15 12L9 17" />
      </svg>
      웹캠 공유
    </Button>
  );
  
  const ScreenOffButton = () => (
    <Button>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="2" y="2" width="20" height="20" rx="5" />
        <path d="M10 8h4M16 8h4" />
        <path d="M12 16h-4" />
      </svg>
      화면 끄기
    </Button>
  );
  
  const MicMuteButton = ({handleAudioMute}) => (
    <Button onClick={handleAudioMute}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 11a5 5 0 0 1-5-5H9a5 5 0 0 0-5 5v4m4 0H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h4" />
        <path d="M19 11H5" />
      </svg>
      음소거
    </Button>
  );


  const [Width, setWidth] = useState(100); // 예시 초기 크기 설정
  const ref = useRef();

  const handleStyle = {
    position:'absolute',
    background: 'black',
    height:'10px',
    width: '10px',
    cursor: 'se-resize',
    aspectRatio: 'auto',
    right:'0px',
    bottom:'0px',
    margin:'0px',
  };
  useEffect(() => {
    if (peer?.peer) {
      peer.peer.on('stream', (stream) => {
        if (ref.current) {
          ref.current.srcObject = stream;
        }
      });
    }
  }, [peer]);

  const handleResize = (event, { size }) => {
    setWidth(size.width);
  };

  return (
    <Resizable 
    width={Width}
    onResize={handleResize}
    handle={
      <span
        style={handleStyle}
        onClick={(e) => {
          e.stopPropagation(); // 이벤트 버블링 방지
        }}
      />
    }>
    <VideoContainer width={Width}>
      <PeerVideoDiv controls playsInline autoPlay ref={ref}/>
      <ControlButtons>
          <ScreenShareButton handleScreenShare={handleScreenShare} />
          <WebcamShareButton handleWebcamShare={handleWebcamShare}/>
          <ScreenOffButton />
          <MicMuteButton handleAudioMute={handleAudioMute} />
      </ControlButtons>
    </VideoContainer>
    </Resizable>
  );
};

export default Video;