import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../../../style.css';

function NewsViewer(props) {
    return (
        <div className='M_게시판_컨테이너'>
            <span style={{ display: 'flex', justifyContent:"space-between" , alignItems:'center', height: '40px' }}>
                <Link className='HeaderLink' to={'/NewsBoard/' + 0}>
                    <label style={{ fontSize: '16pt', fontWeight: '1000' }}>{props.title}</label>
                </Link>
                <Link className='HeaderLink' to={'/NewsBoard/' + 0}>
                    <label style={{ fontSize: '14pt', fontWeight: 'bold' , marginRight:'15px', color:'#999'}}>더보기</label>
                </Link>
            </span>
            <hr color='#eaeaea' />
            <table className='M_공지사항_게시판'>
                <thead />
                <tbody>
                    {props.NewsData.map((Data, i) =>
                    (
                        <tr style={{ height: '35px' }} key={i}>
                            <td><Link className='NewsLink' to={'/NewsBoard/' + Data.NUM}><label style={{ fontSize: '13pt', fontFamily: 'Pretendard-Regular' }}>{Data.NOTICE_TITLE}</label></Link></td>
                            <td><div align='center'><label style={{ fontSize: '13pt', fontFamily: 'Pretendard-Regular' }}>{Data.NOTICE_NAME}</label></div></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default NewsViewer