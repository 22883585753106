import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import styled from 'styled-components';
import Video from '../Video';
import './style.css';
import { Resizable } from 'react-resizable';
import WRBtn from '../WRBtnImg';

const Container = styled.div`
  display: flex;
  margin: auto ;
  flex-wrap: wrap;
  justify-content: center;
  padding:10px;
`;
const MyVideoDiv = styled.video`
  border: 1px solid red;
  width: ${(props) => props.width}px;
  border: 1px solid red;
  border-radius: 5px;
  position: initial;
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;  // 비디오 너비를 100%로 설정하여 부모 요소에 맞춤
`;


function VideoChat() {
  const [peers, setPeers] = useState([]);

  const info = useParams();
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);


 

  
  useEffect(() => {
    socketRef.current = io.connect('https://edu.antsnest.co.kr');
    // Here we changed getUserMedia to getDisplayMedia
    navigator.mediaDevices.getDisplayMedia({ video: { mediaSource: 'screen'}, audio: true }).then((stream) => {
      userVideo.current.srcObject = stream;
      socketRef.current.emit('join room', info.roomId);
      socketRef.current.on('user count', count => {
        console.log(count);
        //setUserCount(count);
      });

      socketRef.current.on('all users', (users) => {
        const peers = [];
        users.forEach((userID) => {
          const peer = createPeer(userID, socketRef.current.id, stream);
          peersRef.current.push({
            peerID: userID,
            peer,
          });
          peers.push({
            peerID: userID,
            peer,
          });
        });
        setPeers(peers);
      });

      socketRef.current.on('user joined', (payload) => {
        const peer = addPeer(payload.signal, payload.callerID, stream);
        peersRef.current.push({
          peerID: payload.callerID,
          peer,
        });

        const peerObj = {
          peer,
          peerID: payload.callerID,
        };

        setPeers((users) => [...users, peerObj]);
      });

      socketRef.current.on('receiving returned signal', (payload) => {
        const item = peersRef.current.find((p) => p.peerID === payload.id);
        item.peer.signal(payload.signal);
      });
    });
      // 클린업 함수
      return () => {
        // 사용자가 페이지를 떠날 때 발생
        socketRef.current.emit('leave room', {
            roomId: info.roomId,
            userId: socketRef.current.id
        });
        userVideo.current.srcObject.getTracks().forEach(track => track.stop());
    };
  }, []);

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on('signal', (signal) => {
      socketRef.current.emit('sending signal', { userToSignal, callerID, signal });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on('signal', (signal) => {
      socketRef.current.emit('returning signal', { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const leftBox={
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  width:'10%', 
  }
  const rightBox={
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'10%',
    }

  return (
    <Container>
        <div style={leftBox} className="left-side">
        {/* <section style={{position:'absolute',display:'flex',top:'15vh',left:'0px',flexDirection:'column'}}> */}
        {[...Array(3)].map((_, index) => {
            if (peers[index]) {
              return <Video key={index} peer={peers[index]} />;
            } else {
              return <div style={{border: '1px solid #cfcfcf',width: '90%',aspectRatio:'2/1.4',minHeight:'250px',margin:'10px' ,borderRadius: '5px'}} key={index} />;
            }
          })}
          {/* </section> */}
        </div>
        <MasterScreen userVideo={userVideo} />
        <div style={rightBox}>
        {/* <section style={{position:'absolute',display:'flex',top:'15vh',right:'0px',flexDirection:'column'}}> */}
        {[...Array(3)].map((_, index) => {
            if (peers[index+3]) {
              return <Video key={index} peer={peers[index+3]} />;
            } else {
              return <div style={{border: '1px solid #cfcfcf',width: '90%',aspectRatio:'2/1.4',minHeight:'250px',margin:'10px' ,borderRadius: '5px'}} key={index} />;
            }
          })}
          {/* </section> */}
        </div>

    </Container>
  );
}

export default VideoChat;
const Button = styled.button`
  width: 25%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }
`;
const ScreenShareButton = ({handleScreenShare}) => (
  <Button onClick={handleScreenShare}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="2" width="20" height="20" rx="5" />
      <path d="M16 10h4" />
      <path d="M12 14L8 18" />
      <path d="M16 18L8 14" />
    </svg>
    화면 공유
  </Button>
);

const WebcamShareButton = (handleWebcamShare) => (
  <Button onClick={handleWebcamShare}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 7L15 12L9 17" />
    </svg>
    웹캠 공유
  </Button>
);

const ScreenOffButton = () => (
  <Button>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="2" width="20" height="20" rx="5" />
      <path d="M10 8h4M16 8h4" />
      <path d="M12 16h-4" />
    </svg>
    화면 끄기
  </Button>
);

const MicMuteButton = ({handleAudioMute}) => (
  <Button onClick={handleAudioMute}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 11a5 5 0 0 1-5-5H9a5 5 0 0 0-5 5v4m4 0H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h4" />
      <path d="M19 11H5" />
    </svg>
    음소거
  </Button>
);
function UserBox(){
  return (
    <section style={{width: '20%' , display: 'flex' , flexDirection:'column' , borderRadius:'10px', backgroundColor:'#fcfcfc'}}>
    <ul style={{height: '40%' , marginTop:'0' ,borderRadius:'10px 10px 0 0 ', padding:'10px'}} className='ChatList'> 참가자(5)
      <li style={{marginTop:'10px', display:'flex', justifyContent:'space-between'}}>
        용수현(호스트,나)
        <WRBtn></WRBtn>
      </li>
      <li style={{display:'flex', justifyContent:'space-between'}}>
        김나희
        <WRBtn></WRBtn>
      </li>
      <li style={{display:'flex', justifyContent:'space-between'}}>
        김다솜
        <WRBtn></WRBtn>
      </li >
      <li style={{display:'flex', justifyContent:'space-between'}}>
        이연수
        <WRBtn></WRBtn>
      </li>
      <li style={{display:'flex', justifyContent:'space-between'}}>이은지
        <WRBtn></WRBtn>
      </li>
    </ul>
    <ul className='BtnBox' style={{display:'flex' , justifyContent:'space-evenly' }}>
      <li>초대</li>
      <li>모두 음소거</li>
      <li>...</li>
    </ul>
    <div style={{height:'60%' }}>
      <div className='View' style={{height:'74%'}}>
        채팅
      </div>
      <div className='InputView'>
        <div className='InputViewTop'>
          <label>수신자 : </label>
          <select style={{cursor:'pointer'}}>
            <option>모두에게</option>
            <option>용수현</option>
            <option>김나희</option>
            <option>김다솜</option>
            <option>이연수</option>
            <option>이은지</option>
          </select>
          <button style={{marginLeft:'40%', marginRight:'0'}}>...</button>
        </div>
        <div className='InputViewBottom' >
          <textarea placeholder='내용을 입력하세요'></textarea>
          {/* <button type='submit' style={{ whiteSpace:'nowrap'}}>입력</button> */}
        </div>

      </div>
    </div>
  </section>
  )
}

function MasterScreen({ userVideo }) {
  useEffect(() => {
    // 부모 div의 너비를 가져와 width 설정
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      setWidth(parentWidth);
    }
  }, []);
  const [userCount, setUserCount] = useState(0);  
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isWebcamOn, setIsWebcamOn] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [width, setWidth] = useState(100);// 초기 너비 설정
  const handleWebcamShare = () => {
    setIsWebcamOn(current => !current);
    if (!isWebcamOn) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: isAudioMuted ? false : true }).then((stream) => {
            userVideo.current.srcObject = stream;
        }).catch(err => {
            console.error("Error: ", err);
        });
    } else {
        // 웹캠을 끄는 로직, 스트림을 끄거나 UI 업데이트
    }
};
const handleAudioMute = () => {
  setIsAudioMuted(current => !current);
  userVideo.current.srcObject.getAudioTracks().forEach(track => {
      track.enabled = !track.enabled;
  });
};
  const handleScreenShare = () => {
    if (!isScreenSharing) {
        navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
            // 본인의 비디오 스트림을 화면 공유 스트림으로 전환
            userVideo.current.srcObject = stream;
            setIsScreenSharing(true);
        }).catch(err => {
            console.error("Error: ", err);
        });
    } else {
        // 화면 공유를 중지하고 웹캠 스트림으로 전환(웹캠이 켜져있다면)
        if(isWebcamOn) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
                userVideo.current.srcObject = stream;
                setIsScreenSharing(false);
            }).catch(err => {
                console.error("Error: ", err);
            });
        } else {
            setIsScreenSharing(false);
        }
    }
};
  const ControlButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

  const handleStyle = {
    position:'absolute',
    background: 'black',
    height:'10px',
    width: '10px',
    cursor: 'se-resize',
    aspectRatio: 'auto',
    right:'0px',
    bottom:'0px',
    margin:'0px',
  };
  
  const handleResize = (event, { size }) => {
    setWidth(size.width);
    //userVideo.current.style.width = `${width}px`;
  };

  const parentRef = useRef(null);
  const centerBox={
    flex: '3',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    width:'80%',
  }
  return(<div ref={parentRef}  style={centerBox} className='center'>
  <section style={{display:'flex',flexDirection:'column',width:'90%'}}>
    <div style={{display:'flex'}}>현재 접속자 수: {userCount}</div> 
    <div id='Screens' style={{display:'flex',flexWrap:'wrap',justifyContent:'center',width:width}}>
    <Resizable
    width={width}
    onResize={handleResize}
    handle={
      <span
        style={handleStyle}
        onClick={(e) => {
          e.stopPropagation(); // 이벤트 버블링 방지
        }}
      />
    }
  >
    <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
        <MyVideoDiv controls muted ref={userVideo} autoPlay playsInline/>
        <ControlButtons>
          <ScreenShareButton handleScreenShare={handleScreenShare} />
          <WebcamShareButton handleWebcamShare={handleWebcamShare}/>
          <ScreenOffButton />
          <MicMuteButton handleAudioMute={handleAudioMute} />
        </ControlButtons>
        </div>
        </Resizable>
    </div>
    </section>
    </div>
    )
}