import React, { createRef } from 'react';
import Imgb1 from '../../../image/ImageSkill.gif';
import '../../style.css';
import bizskill from '../../../image/design/bizskill.png';
import BizSkillAll from '../../../image/design/BizSkillAll.png';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

function BizSkill() {
  const BizPlan = createRef();
  const BizMember = createRef();
  const BizSkill = createRef();
  const MenuArray = [BizPlan, BizMember, BizSkill];

  const ActiveCheck = (Menu, MenuArray) => {
    try {
      MenuArray.map((Item, Index) => {
        if (Item.current.className === 'TabActive')
          Item.current.className = '';
      })
      if (Menu.current.className === 'TabActive')
        Menu.current.className = '';
      else
        Menu.current.className = 'TabActive';
    }
    catch (err) {

    }
  }

  let BizMemberTitle = {
    padding: "2px",
    textAlign: "center",
    backgroundColor: "#474e5d",
    color: "white"

  }
  return (
    <div className='Wrapper'>
      <div className='TabControl'>
        <div className='TabPageBig'>
          <ul className="SubNavLeft">
            <li><Link to="/Company" ref={BizPlan} id='BizPlan' onClick={() => ActiveCheck(BizPlan, MenuArray)}> 사업계획 </Link></li>
            <li><Link to='/BizSkill' ref={BizSkill} id="BizSkill" className="TabActive" onClick={() => ActiveCheck(BizSkill, MenuArray)}>기술이력 </Link></li>
            <li><Link to="/BizMember" ref={BizMember} id='BizMember' onClick={() => ActiveCheck(BizMember, MenuArray)}> 구성원</Link></li>
          </ul>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='BizSkillMasterPanel'>
            <img src={bizskill} style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }}></img>
            <div>
              <h1>기술이력</h1>
              <h4>앤츠네스트는 메타버스 세상을 만들어갑니다.</h4>
            </div>
            <img src={BizSkillAll}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BizSkill;
