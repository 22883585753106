import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route,Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LoginIcon from '@material-ui/icons/Person';
import Home from '../../../Screen/Home/Mobile';
import Company from '../../../Screen/Company/mobile';
import Product from '../../../Screen/Product/WindowsApp/mobile';
import Community from '../../../Screen/Community/mobile';
import Help from '../../../Screen/Help';
import Portfolio from '../../../Screen/WorkRoom/Portfolio/mobile';
import Download from '../../../Screen/Download/WindowsApp/mobile';
import MLOGO from '../../../image/design/MLOGO.png';
import { Height } from '@material-ui/icons';
import './MobileStyle.css';
import { pink } from '@material-ui/core/colors';
import MLgIcon from '../../../image/design/MLoginIcon.png';
import MXButton from '../../../image/design/MXButton.png';
import TButton from '../../../image/design/triangle.png';

const styles = {
    root: {
    width:"100%",
    },
    menuButton: {
    flexGrow:1,
    width:"100%",
    height:"100%",
    marginRight: "auto",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    paddingLeft:'0px',
    paddingRight:'12px',
    paddingTop:'0px',
    paddingBottom:'0px',
    display: 'flex',
    },
    Icon:{
        // width:"45px",
        // height:"45px",
    },
    Link:{
        width:"45px",
        height:"45px",
    }
};

// const ShowSubMenu = (e)=>{
//     if (e.target.className.includes('active')) {
//         // 'active'가 포함되어 있다면, 해당 클래스를 제거합니다.
//         e.target.className = e.target.className.replace('active', '').trim();
//       } else {
//         // 'active'가 포함되어 있지 않다면, 해당 클래스를 추가합니다.
//         e.target.className += ' active';
//       }
      
// } 

class AppShell extends React.Component {

constructor(props) {
    super(props);
    
    this.state = {
        toggle: false,
        menuState:{ //하나의 상태로 MMenu와 SubMenu상태 관리
            isFlipped: false, // 이미지 뒤집힘 여부 상태
            isSubMenuVisible: false,
        },
        
    };
}

toggleMenu = (menuId) => {
    this.setState((prevState) =>({
        menuState: {
            // isFlipped : !prevState.menuState.isFlipped,
            // isSubMenuVisible: !prevState.menuState.isSubMenuVisible,
            ...prevState.menuState,
            [menuId]: !prevState.menuState[menuId],
        }
    }));
};

ActiveCheck(id){
    this.handleDrawerToggle();
}

handleDrawerToggle = () => {this.setState({ toggle: !this.state.toggle });}

render() {
    const { classes } = this.props;
    
    return (
            <div className={classes.root}>
                <AppBar className='MobileAppBar ' style={{ background: '#00BED8' }} position="static">
                    <span id='Metro' style={{display:'flex', justifyContent:'space-between',height:'100%'}}>
                        <div id='Mlogo' style={{marginTop:'5px', marginLeft:'15px',lineHeight:'35px'}}>
                        <Link style={{textDecoration:'none'}} to='/'><label style={{fontFamily:'Pretendard-ExtraBold',fontSize:'20pt'}}>ANTSNEST</label></Link>
                        {/* <Link style={{textDecoration:'none'}} to='/'><img src={MLOGO}></img></Link> */}
                        </div>
                        <div>
                            <IconButton className={classes.menuButton} color="inherit">
                            <Link to='/Login' className={classes.Link}><LoginIcon style={{fontSize:'2.3rem', marginTop:'4px'}} onClick={this.Login} className={classes.Icon}/></Link>
                            <Link className={classes.Link}><MenuIcon  style={{fontSize:'2.3rem', marginTop:'4px'}} onClick={this.handleDrawerToggle} className={classes.Icon}/></Link>
                            </IconButton>
                        </div>
                    </span>
                </AppBar>

                <Drawer open={this.state.toggle} className="Drawer "> 
                    <div className='MButton'>
                        <IconButton className={classes.menuButton} color="inherit">
                        <Link to='/Login' className={classes.Link}><img src={MLgIcon} onClick={this.Login} className={classes.Icon} width={20} height={20}></img></Link>
                        <Link className={classes.Link}><img src={MXButton} onClick={this.handleDrawerToggle} className={classes.Icon} width={20} height={20}></img></Link>
                        </IconButton>
                    </div>
                    <ul>
                        <li className='MMenu'><Link to='/'><MenuItem onClick={()=>this.ActiveCheck('HOME')} style={{fontFamily:'Pretendard-Regular', padding:'0'}}>앤츠네스트 홈</MenuItem></Link></li>
                        <li className='SubMenuLi'></li>

                        <li className='MMenu' onClick={() => this.toggleMenu('menu1')}>
                            사업기획
                            <button>
                                <img id='flipImg-1' src={TButton} alt="TButton"  className={`flipImg ${this.state.menuState['menu1'] ? 'active' : ''}`} ></img>
                            </button>
                          
                        </li>
                        <li className='SubMenuLi'>
                            <ul className={`SubMenu ${this.state.menuState['menu1'] ? 'visible' : '' }`}>
                                <li><Link to='/Company'><MenuItem onClick={()=>this.ActiveCheck('COMPANY',)} style={{fontFamily:'Pretendard-Regular'}}>사업계획</MenuItem></Link></li>
                                <li><Link to='/BizSkill'><MenuItem onClick={()=>this.ActiveCheck('BIZSKILL')} style={{fontFamily:'Pretendard-Regular'}}>기술이력</MenuItem></Link></li>
                                <li><Link to='/BizMember'><MenuItem onClick={()=>this.ActiveCheck('BIZMEMBER')} style={{fontFamily:'Pretendard-Regular'}}>구성원</MenuItem></Link></li>
                            </ul>
                        </li>

                        <li className='MMenu' onClick={() => this.toggleMenu('menu2')}>
                            제품설명
                            <button>
                                <img id='flipImg-2' src={TButton} alt="TButton"  className={`flipImg ${this.state.menuState['menu2'] ? 'active' : ''}`}></img>
                            </button>
                        </li>
                        <li className='SubMenuLi'>
                            <ul className={`SubMenu ${this.state.menuState['menu2'] ? 'visible' : '' }`}>
                                <li><Link to='/WindowsProduct'><MenuItem onClick={()=>this.ActiveCheck('PRODUCET')} style={{fontFamily:'Pretendard-Regular'}}>윈도우앱</MenuItem></Link></li>
                                <li><Link to='/MobileProduct'><MenuItem onClick={()=>this.ActiveCheck('M_PRODUCET')} style={{fontFamily:'Pretendard-Regular'}}>모바일앱</MenuItem></Link></li>
                                <li><Link to='/UnityProduct'><MenuItem onClick={()=>this.ActiveCheck('U_PRODUCET')} style={{fontFamily:'Pretendard-Regular'}}>유니버셜앱</MenuItem></Link></li>
                                {/* <li><Link to='/WindowsProduct'><MenuItem onClick={()=>this.ActiveCheck('PRODUCET')} style={{fontFamily:'Pretendard-Regular'}}>API</MenuItem></Link></li> */}
                            </ul>
                        </li>

                        <li className='MMenu' onClick={() => this.toggleMenu('menu3')}>
                            다운로드
                            <button>
                                <img id='flipImg-3' src={TButton} alt="TButton"  className={`flipImg ${this.state.menuState['menu3'] ? 'active' : ''}`}></img>
                            </button>
                        
                        </li>
                        <li className='SubMenuLi'>
                            <ul className={`SubMenu ${this.state.menuState['menu3'] ? 'visible' : '' }`}>
                                <li><Link to='/WindowsDown'><MenuItem onClick={()=>this.ActiveCheck('DOWNLOAD')} style={{fontFamily:'Pretendard-Regular'}}>윈도우앱</MenuItem></Link></li>
                                <li><Link to='/MobileDown'><MenuItem onClick={()=>this.ActiveCheck('M_DOWNLOAD')} style={{fontFamily:'Pretendard-Regular'}}>모바일앱</MenuItem></Link></li>
                                <li><Link to='/UnityDown'><MenuItem onClick={()=>this.ActiveCheck('U_DOWNLOAD')} style={{fontFamily:'Pretendard-Regular'}}>유니버셜앱</MenuItem></Link></li>
                                {/* <li><Link to='/WindowsDown'><MenuItem onClick={()=>this.ActiveCheck('DOWNLOAD')} style={{fontFamily:'Pretendard-Regular'}}>API</MenuItem></Link></li> */}
                            </ul>
                        </li>

                        <li className='MMenu' onClick={() => this.toggleMenu('menu4')}>
                            작업실
                            <button>
                                <img id='flipImg-4' src={TButton} alt="TButton"  className={`flipImg ${this.state.menuState['menu4'] ? 'active' : ''}`}></img>
                            </button>
                        </li>
                        <li className='SubMenuLi'>
                            <ul className={`SubMenu ${this.state.menuState['menu4'] ? 'visible' : '' }`}>
                                <li><Link to='/WorkRoom'><MenuItem onClick={()=>this.ActiveCheck('WORKROOM')} style={{fontFamily:'Pretendard-Regular'}}>채팅방</MenuItem></Link></li>
                            </ul>
                        </li>

                        <li className='MMenu' onClick={() => this.toggleMenu('menu5')}>
                            고객지원
                            <button>
                                <img id='flipImg-5' src={TButton} alt="TButton"  className={`flipImg ${this.state.menuState['menu5'] ? 'active' : ''}`}></img>
                            </button>
                        </li>
                        <li className='SubMenuLi'>
                            <ul className={`SubMenu ${this.state.menuState['menu5'] ? 'visible' : '' }`}>
                                <li><Link to='/Inquiry'><MenuItem onClick={()=>this.ActiveCheck('INQUIRY')} style={{fontFamily:'Pretendard-Regular'}}>문의사항</MenuItem></Link></li>
                                <li><Link to='/Bug'><MenuItem onClick={()=>this.ActiveCheck('BUG')} style={{fontFamily:'Pretendard-Regular'}}>버그리포트</MenuItem></Link></li>
                                <li><Link to='/Suggestions'><MenuItem onClick={()=>this.ActiveCheck('SUGGESTIONS')} style={{fontFamily:'Pretendard-Regular'}}>건의함</MenuItem></Link></li>
                            </ul>
                        </li>
                    </ul>
                    
                    {/* <Link to='/WindowsProduct'><MenuItem onClick={()=>this.ActiveCheck('PRODUCET')}>제품설명</MenuItem></Link> */} 
                    {/* <Link to='/WorkRoom'><MenuItem onClick={()=>this.ActiveCheck('WORKROOM')}}>작업실</MenuItem></Link> */}
                    {/* <Link to='/Portfolio'><MenuItem onClick={()=>this.ActiveCheck('PORTPOLIO')}>포트폴리오</MenuItem></Link> */}
                    {/* <Link to='/WBS'><MenuItem onClick={()=>this.ActiveCheck('WBS')}>일정관리</MenuItem></Link> */}
                    {/* <Link to='/join'><MenuItem onClick={()=>this.ActiveCheck('CHATROOM')}>채팅방</MenuItem></Link> */}
                    {/* <Link to='/Community'><MenuItem onClick={()=>this.ActiveCheck('COMMUNITY')}>커뮤니티</MenuItem></Link> */}
                    
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(AppShell);