import React, { useState, useEffect, useContext ,createRef} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LoginBox from '../../Common/Component/LoginBox/Login'
import LogoutBox from '../../Common/Component/LoginBox/Logout'
import { Redirect } from 'react-router';
import { HomeViewContext, withContext } from './HomeViewContext';
import ThumbnailViewer from '../../Common/Component/ThumbnailViewer';
import * as property from './HomeViewProperty.js';
import * as actions from './HomeViewActions.js';
import * as service from './HomeViewService.js';
import news from '../../image/design/news.jpg';
import freeboard from '../../image/design/freeboard.jpg';
import BrandLogo from '../../image/BrandLogo.png';
import StoreLogo from '../../image/StoreLogo.png';
import NodeLogo from '../../image/NodeLogo.png';
import AdminLogo from '../../image/AdminLogo.png';
import EstimateLogo from '../../image/EstimateLogo.png';
import bigData from '../../image/bigData.jpg';
import vrarLogo from '../../image/vrarLogo.jpg';
import logic from '../../image/logic.png';
import AILogo from '../../image/AILogo.png';
import web3 from '../../image/web3.png';


import NewsViewer from './parts/NewsViewer';
import '../style.css';

function HOME() {
  const { context, dispatch } = useContext(HomeViewContext); //모듈 전역 컨텍스트
  const [state, setState] = useState(property.InitializeState); //모듈 지역 스테이트 //
  const view = { state, setState, context, dispatch };
  const ROUNGE = createRef();
  const PHOTO = createRef();
  const UPLOADER = createRef();
  const MenuArray = [ROUNGE, PHOTO,UPLOADER];
  const ActiveCheck = (Menu, MenuArray) => {
    try {
      MenuArray.map((Item, Index) => {
        if (Item.current.className === 'TabActive')
          Item.current.className = '';
      })
      if (Menu.current.className === 'TabActive')
        Menu.current.className = '';
      else
        Menu.current.className = 'TabActive';
    }
    catch (err) {

    }
  }
  useEffect(() => { actions.InitializeScreen(view); }, []);
  return (
    <div id='WrapperBox' className='Wrapper'>
      {state.Session.State != 'LOGIN' ? '' : <Redirect to='/' />}
      <div className='TabControl'>
        <div className='TabPageBig'>
        <ul style={{display:'flex',borderBottomLeftRadius:'15px',borderBottomRightRadius:'15px',paddingInlineStart:'0px',margin:'0px'}}>
            <li><Link to="/" className="TabActive" ref={ROUNGE} id='ROUNGE' onClick={() => ActiveCheck(ROUNGE, MenuArray)}> 라운지 </Link></li>
            <li><Link to='/PHOTO' ref={PHOTO} id="PHOTO" onClick={() => ActiveCheck(PHOTO, MenuArray)}>사진첩</Link></li>
            <li><Link to='/Uploader' ref={UPLOADER} id="PHOTO" onClick={() => ActiveCheck(UPLOADER, MenuArray)}>멀티 사진첩</Link></li>
        </ul>
      <div className='HomeMasterPanel'>
        {state.Session.State != 'LOGIN' ? 
        <LoginBox getSession={() => actions.getSession(view)} /> 
        :
         <LogoutBox UserInfo={state.Session} getSession={() => actions.getSession(view)} />}
        <div className="컨텐츠">
          <NewsViewer NewsData={state.공지사항_게시글} title={news} />
          <NewsViewer NewsData={state.자유게시판_게시글} title={freeboard} />
          <ThumbnailViewer />
        </div>
        <section style={{width:'100%', display:'flex',justifyContent:'space-evenly',background:'#fff',borderTopLeftRadius:'30px',borderTopRightRadius:'30px',boxShadow:'2px 2px 2px 2px #f1f1f1'}}>
          <a href='https://antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px' src={BrandLogo}></img></a>
          <a href='https://store.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={StoreLogo}></img></a>
          <a href='https://admin.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={AdminLogo}></img></a>
          <a href='https://estimate.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={EstimateLogo}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={NodeLogo}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={bigData}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={vrarLogo}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={logic}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={AILogo}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='70px' height='70px'  src={web3}></img></a>
        </section>
      </div>
      </div>
      </div>
    </div>
  );
}
export default withContext(HOME);

