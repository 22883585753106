import { SettingsEthernetRounded, ToggleOnOutlined } from '@material-ui/icons';
import React, { createRef, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Person from '@material-ui/icons/Person';
import Favorite from '@material-ui/icons/Favorite';
import Grade from '@material-ui/icons/Grade';
import camerabtn from '../../../../image/design/PHOTO.jpg';
import PhotoUpload from '../../../../image/design/PhotoUpload.png';
import morebtn from '../../../../image/design/morebtn.png';
import Share from '../../../../image/design/share.jpg';
import Heart from '../../../../image/design/heart.jpg';
import Star from '../../../../image/design/star.jpg';
import '../style.css';

function Timeline(props) {
  useEffect(() => {
    ReadProfile();
  }, []);
  const PhotoSubmit = createRef(); // dom 에서 ref 기능으로 변경
  const [SendData, setSendData] = useState({ "num": "", "name": "", "write": "", "contents": "", "textCommentIndex": "" });
  const [Feed, setFeed] = useState([{}]);
  const [Comment, setComment] = useState({});
  const [Session, setSession] = useState({});
  const [profile, setProfile] = useState({ "fileName": "NoImage.png" });
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setSession(msg) })
      .catch(err => alert(err + '오류'));
  }
  // 프로필 파일명 세팅
  const sendImage = (data) => {
    if (data.length === 0) {
      setProfile({ ...profile, fileName: 'NoAvatar.png' });
    }
    else {
      setProfile({ ...profile, fileName: data[0].file_name });
    }
  }
  // 프로필 사진 가져오기
  const ReadProfile = () => {
    fetch('/request/ReadProfile', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "UserId": Session.UserId })
    })
      .then(res => res.json())
      .then(msg => sendImage(msg))
      .catch(err => alert(err));
  }
  const uploadImgPreview = () => {
    let fileinfo = document.getElementById("files").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      document.getElementById("thumbnailImg").src = reader.result;
      document.getElementById("thumbnailImg").width = '180';
      document.getElementById("thumbnailImg").height = '180';
      document.getElementById("thumbnailImg").style.margin = '20px';
      document.getElementById("thumbnailImg").style.display = 'inline-flex';
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }

  // 게시글 목록 초기화
  const clearData = (i) => {

    PhotoSubmit.current.disabled = false;
    document.getElementById("img").value = null;
    document.getElementById("TextInput").value = null;
    document.getElementById("thumbnailImg").src = null;
    document.getElementById("thumbnailImg").width = '0';
    document.getElementById("thumbnailImg").height = '0';
    document.getElementById("thumbnailImg").style.margin = '0';
    document.getElementById("thumbnailImg").style.display = 'none';

    if (i !== undefined) {
      document.getElementById(`textComment${i}`).value = '';
    }

    GetAllFeedPhoto_ORG();
  }
  // 게시글 전체 불러오기
  const GetAllFeedPhoto_ORG = () => {
    fetch('/request/GetAllFeedPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => setFeed(msg))
      .catch(err => alert(err + '오류'));
  }

  // 댓글 등록 후 재조회
  const InsertComment = (Data) => {

    fetch('/request/InsertPhotoComment', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .then(() => clearData(Data.textCommentIndex))
      .catch(err => alert('InsertComment' + err));
  }

  const InsertSetsendData = (Data, i) => {
    var commentData = { ...SendData, "num": Data.pix_num, "textCommentIndex": i };

    InsertComment(commentData);
  }

  // 댓글 삭제 후 재조회
  const DeleteComment = (Data) => {
    fetch('/request/DeletePhotoComment', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .then(() => clearData())
      .catch(err => alert('DeleteComment' + err));
  }

  // 사진 업로드
  const uploadImage = () => {
    PhotoSubmit.current.disabled = 'disabled';
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    formData.append('userId',Session.UserId);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/Image', {
      method: 'POST',
      mode: 'cors',
      credentials:'include',
      body: formData
    }).then((res) => {
      clearData();
    })
      .catch(err => 
        console.log(err));
  }

  const PhotoMoreBtn=({Data,Research})=>{
    const [toggle,setToggle]=useState(false);
    const ItemDelete = (key)=>{
      console.log('삭제하려는 항목 번호:'+key)
      fetch('/request/ItemDelete', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({Key:key})
      })
        .then(() => {
          alert(key+'삭제완료');
          Research();
      })
        .catch(err => alert('ItemDelete' + err));
    }
    return (<>
    <div style={{ position: 'relative'}}>
      <img src={morebtn} alt="More" style={{ width: '35px', height: '35px', lineHeight: '25px', margin: '20px', cursor: 'pointer' }} onClick={() => setToggle(!toggle)} />
      <section style={{
        display: toggle ? 'block' : 'none',
        background: '#fff',
        width: '200px',
        height: '100px',
        position: 'absolute',
        top: '20px', // 예를 들어, 모어 버튼의 높이 + 마진을 기준으로 설정
        left: '85px', // 팝업창을 모어 버튼의 왼쪽 가장자리로 정렬
        borderRadius:'5px',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.1)'
      }}>
        <section>
          <figure style={{cursor:'pointer'}}>수정</figure>
          <figure style={{cursor:'pointer'}} onClick={()=>ItemDelete(Data.pix_num)}>삭제</figure>
        </section>
        
      </section>
    </div>
    </>)
  }
  useEffect(() => {
    CreateSession();
    GetAllFeedPhoto_ORG();
  }, []);

  const autoGrow = (element) => {
    element.target.style.height = "0px";
    element.target.style.height = (element.target.scrollHeight) + "px";
  }
  return (
    <div id='timeline'>
      <div className='WriteModeBox'>
        <div className='SubmitControl'>
          <form id='myForm' method="POST" encType="multipart/form-data" >
            <div style={{ display: 'flex', height: 'inherit' }}>
            <img style={{ marginLeft: '10px', marginTop: '20px' }} id='모바일썸네일' className='프로필_이미지_모바일' src={profile.fileName===undefined?process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + 'NoAvatar.png':process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' +profile.fileName} />
              <div style={{ width: '600px' }} className='작성자정보'>
                <textarea onInput={(e) => autoGrow(e)} style={{ height: '35px', backgroundColor: '#f7f7f7', borderStyle: 'none', borderRadius: '10px', resize: 'none', overflow: 'hidden', minHeight: '35px', minWidth: '100%', borderWidth: '1px', padding: '10px', margin: '23px 0px 23px 5px' }} placeholder="내용을 입력해 주세요." id='TextInput' name="TextInput" className='사진_본문입력기' value={SendData.contents} onChange={(ev) => setSendData({ ...SendData, contents: ev.target.value })}></textarea>
                <label for='img' style={{ position: 'relative', height: '30px', left: '-40px', top: '25px', borderStyle: 'none', backgroundColor: '#f7f7f7', cursor: 'pointer' }}>
                  <img src={PhotoUpload} style={{ width: '30px', height: '30px' }}>
                  </img>
                </label>
              </div>


              <span style={{ display: 'flex', paddingLeft: '10px' }}>
                <input style={{ borderWidth: '0px', display: 'none' }} id='img' type="file" name="img" onChange={() => uploadImgPreview()} />
              </span>
              <button type='button' style={{ borderStyle: 'none', backgroundColor: '#fff', margin: '10px', cursor: 'pointer' }} ref={PhotoSubmit} className='SubmitButton' onClick={() => uploadImage()}>
                <img src={camerabtn}></img>
              </button>
            </div>
          </form>
          <div id='ImgBox' style={{ backgroundColor: 'white', padding: '1px', height: 'auto' }} className='ThumbnailView'>
            <img style={{ backgroundColor: 'white' ,display:'none'}} id="thumbnailImg" src="" />
            <div style={{ backgroundColor: 'white' }} id="thumbnailSrc" src="" />
          </div>
        </div>
      </div>

      {Feed.map(function (Data, i) {
        return (
          <div className='ReadModeBox'>
            <div className='ImgScroll'
              style={{ borderRadius: '15px', backgroundColor: 'white', boxShadow: '0 0 3px silver' }}>
              <div className='WriteModeHeader'
                style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '10px', backgroundColor: 'white', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <img style={{ marginLeft: '15px', marginTop: '15px', width: '60px', height: '60px' }} id='test3' className='프로필_이미지' src={Data.file_name === undefined? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/NoAvatar.png':process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.file_name} />
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ marginLeft: '15px', lineHeight: '35px', fontWeight: 'bold', fontSize: '18px', marginTop: '13px' }}>{Data.pix_owner}</label>
                    <label style={{ marginLeft: '15px', fontSize: '9px', textAlign: 'start' }}> 00:00:00 </label>
                  </span>
                </div>
                <div>
                  {Session.UserId === Data.pix_owner? <PhotoMoreBtn Data={Data} Research={GetAllFeedPhoto_ORG}/>:<></>}
                </div>
              </div>

              <div className='imgPixHeader'>
                {Data.pix_mimetype !== undefined && Data.pix_mimetype.toString().indexOf("video/") !== -1 ?
                  <video controls width="100%">
                    <source src={Data.pix_loadpath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadpath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
                  </video>
                  :
                  <img width="100%" src={Data.pix_loadpath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + Data.pix_loadpath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '60px' }}>
                  <span style={{ marginLeft: '15px', alignSelf: 'center' }}>
                    <img src={Share} style={{ color: 'black', margin: '10px 0px', cursor: 'pointer' }} />
                    <img src={Heart} style={{ color: 'black', margin: '0px 15px 11px 15px', cursor: 'pointer' }} />
                  </span>
                  <span style={{ marginRight: '15px', alignSelf: 'center' }}>
                    <img src={Star} style={{ color: 'black', margin: '10px 0px', cursor: 'pointer' }} />
                  </span>
                </div>
              </div>
              <label style={{ margin: '10px 10px 20px 15px', display: 'flex', fontSize: '10pt' }}>{Data.pix_contents}</label>
              <div id='Comment' className="CommentBox">
                {Feed[i].Comment === undefined ? '' : <label style={{ display: 'flex', margin: '5px 5px 5px 15px', fontSize: '10pt', fontWeight: '1000' }}>댓글 : {Feed[i].Comment.length} 개</label>}
                <div className='댓글_목록'>
                  {Session.UserId == undefined ? <div>로그인을 해야 댓글 작성이 가능합니다.</div>
                    :
                    <div>
                      <form id='myForm' method="POST" encType="multipart/form-data" >
                        <div style={{ display: 'flex', height: 'inherit' }}>
                          
                          <img style={{ marginRight: '5px', marginTop: '20px', width: '40px', height: '40px' }} className='프로필_이미지' src={process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + profile.fileName} />
                          <div id='ImgBox' style={{ backgroundColor: 'white', padding: '1px', height: 'auto' }} className='ThumbnailView'>
                            <img style={{ backgroundColor: 'white' }} id="thumbnailImg" src="" />
                            <div style={{ backgroundColor: 'white' }} id="thumbnailSrc" src="" />
                          </div>

                          <div style={{ width: '100%' }} className='작성자정보'>
                            <textarea id={`textComment${i}`} key={i} onInput={(e) => autoGrow(e)} style={{ height: '35px', backgroundColor: '#f7f7f7', borderStyle: 'none', borderRadius: '10px', resize: 'none', overflow: 'hidden', minHeight: '35px', borderWidth: '1px', padding: '10px', margin: '23px 0px 23px 5px' }} placeholder="댓글을 입력해 주세요" className='사진_본문입력기' onChange={(ev) => setSendData({ ...SendData, "commentText": ev.target.value, })}></textarea>
                            <button style={{ cursor: 'pointer', width: '0px', position: 'relative', height: '30px', left: '-45px', top: '22px', borderStyle: 'none', backgroundColor: '#f7f7f7' }} >
                            </button>
                          </div>

                          <span style={{ display: 'flex', paddingLeft: '10px' }}>
                            <input style={{ borderWidth: '0px', display: 'none' }} id='img' type="file" name="img" onChange={() => uploadImgPreview()} />
                          </span>
                          <button type='button' style={{ borderStyle: 'none', backgroundColor: '#fff', padding: '0px' }} ref={PhotoSubmit} onClick={() => InsertSetsendData(Data, i)}>
                            <img src={camerabtn}></img>
                          </button>
                        </div>
                      </form>
                    </div>
                    // <div className = '댓글_div'>
                    //   <textarea id= {`textComment${i}`} key={i} className = "댓글_textarea" onChange={(ev) => setSendData({...SendData, "commentText":ev.target.value, })}/> {/* 댓글 내용*/}
                    //   <button className = "댓글쓰기_버튼" onClick={() => InsertSetsendData(Data, i)}> 등록 </button><br/>
                    // </div>
                  }
                  {Feed[i].Comment === undefined ? '' : Feed[i].Comment.map(function (commentList, j) {
                    return (
                      <spen style={{ display: 'flex' }}>
                        <img style={{ marginRight: '5px', marginLeft: '0px', marginTop: '10px', marginBottom: '10px', width: '40px', height: '40px' }} className='프로필_이미지' src={process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + commentList.fileName} />
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div style={{ display: 'flex', width: '100%', marginTop: '5px', padding: '10px' }} className='댓글_div'>
                            {commentList.contents}
                            <div className='댓글_시간_div'>{commentList.create_time}</div>
                            {/* {Session.UserId === commentList.USER_ID ? <button className = "댓글삭제_버튼" onClick ={()=>DeleteComment(Feed[i].Comment[j])}>삭제</button>:''}    */}
                          </div>
                          <hr color="white" />
                        </div>
                      </spen>
                    );
                  })}
                  {/* <b>{Session.Name}</b> 댓글 작성자 */}
                  <br />

                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Timeline;