import React, { createRef, useState, useEffect } from 'react';
import Contents_View from './Contents_View';
import WriteMode from './WriteMode';
import ReactDOM from 'react-dom';
import './style.css';

//전역 프로퍼티
var MyPage = 0;
var CurrentMinItem = 0;
var CurrentMaxItem = 0;
var NextButtonEnable = false;

function NewsBoard(props) {
  const Contents_Div = createRef();
  const [게시글, set게시글] = useState([{ "컬럼1": "", "컬럼2": "", "컬럼3": "" }]);
  const [리스트, set리스트] = useState({ "PageCount": 0, "Page": 0 });
  const [페이지리스트, set페이지리스트] = useState([]);
  const [SendData, setSendData] = useState({ "num": "", "id": "", "name": "", "title": "", "contents": "" });
  const [현재컨텐츠, set현재컨텐츠] = useState({});
  const [Session, setSession] = useState({});

  useEffect(() => { // 폼로드와 비슷한 개념
  }, [게시글]);

  const getSession = async (msg) => {
    let MySession = msg;
    await setSession(MySession);
  }
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { getSession(msg) })
      .catch(err => alert(err + '오류'))
  }

  // 액션
  const SelectNews = () => {
    if (props.LinkAction === true)
      props.Selecter('News');

    fetch('/request/SelectNews_ORG')
      .then(res => res.json())
      .then(msg => set게시글(msg))
      .then(setSendData({ "num": "", "id": "", "name": "", "title": "" }))
      .catch(err => alert('SelectNews' + err));
  }

  const DataDelete = (Data) => {
    fetch('/request/DeleteNewsBoard', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    }
    ).then(() => SelectNews()).catch(err => alert('DataDelete' + err));
  }

  const DataUpdate = (SendData, Data) => {
    var Send = { "NUM": Data.NUM, "NOTICE_TITLE": SendData.title, "NOTICE_CONTENTS": SendData.contents }
    fetch('/request/UpdateNewsBoard', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Send)
    }
    ).then(() => SelectNews()).catch(err => alert('DataUpdate' + err));
  }

  const ReadContents = (Data) => {
    fetch('/request/ReadNewsBoard', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    }
    ).then((res) => res.json())
      .then((msg) => ReadComments(msg[0]))
      .then(SearchCountPlus(Data))
      .catch(err => alert('ReadNewsBoard_Read' + err));
  }

  // 조회수 증가
  const SearchCountPlus = (Data) => {
    fetch('/request/NewsSearchCountPlus', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .catch(err => alert('NewsSearchCountPlus' + err));
  }

  // 댓글 가져오기
  const ReadComments = (Data) => {
    fetch('/request/ReadNewsComments', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Data)
    })
      .then((res) => res.json())
      .then((msg) => RefrashContents({ ...Data, "CommentData": msg }))
      .catch(err => alert(err + '오류'));
  }

  const RefrashContents = (컨텐츠) => {
    if (Contents_Div.current != null) {
      if (Contents_Div.current.className == 'Contents_Active') {
        if (현재컨텐츠.NUM == 컨텐츠.NUM) {
          Contents_Div.current.className = 'Contents_Deactive';
          ReactDOM.render(<Contents_View Refrash={() => SelectNews()} />, document.getElementById('Board_Contents'));
        }
        else {
          ReactDOM.render(<Contents_View Subject={컨텐츠}
            CommentData={컨텐츠.CommentData}
            CommentRefrash={() => ReadContents(컨텐츠)}
            Refrash={() => SelectNews()} />
            , document.getElementById('Board_Contents'));
        }
      }
      else if (Contents_Div.current.className == 'Contents_Deactive') {

        Contents_Div.current.className = 'Contents_Active';
        ReactDOM.render(<Contents_View Subject={컨텐츠}
          CommentData={컨텐츠.CommentData}
          CommentRefrash={() => ReadContents(컨텐츠)}
          Refrash={() => SelectNews()} />
          , document.getElementById('Board_Contents'));
      }

      set현재컨텐츠(컨텐츠);
    }
    else {
      document.getElementById('Board_Contents').setAttribute('class', 'Contents_Active');
      ReactDOM.render(<Contents_View Subject={컨텐츠}
        CommentData={컨텐츠.CommentData}
        CommentRefrash={() => ReadContents(컨텐츠)}
        Refrash={() => SelectNews()} />
        , document.getElementById('Board_Contents'));
    }
  }

  const ActionWriteMode = () => {
    set현재컨텐츠({});

    if (Contents_Div.current.className == 'Contents_Deactive') {
      Contents_Div.current.className = 'Contents_Active';
      ReactDOM.render(<WriteMode Refrash={() => SelectNews()} />, document.getElementById('Board_Contents'))
    }
    else {
      ReactDOM.render(<WriteMode Refrash={() => SelectNews()} />, document.getElementById('Board_Contents'))
    }
  }
  useEffect(() => {
    SelectNews();

    if (props.Data != undefined) {
      ReadContents(props.Data);
    }
  }, []);
  return (
    <div className='MobileBoard'>
      <button className='WriteButton' onClick={() => ActionWriteMode()}>신규 글쓰기</button>
      <div ref={Contents_Div} id='Board_Contents' className='Contents_Deactive' />
      {게시글.map((Data, i) =>
      (
        <div className='MobileBoardItem' onClick={() => ReadContents(Data)}>
          <div id='Subject'> <ul><li></li></ul> {Data.notice_title}</div>
          정보[0].itemcount<div id='Writer'> [작성자 : {Data.notice_name}] [조회수: {Data.search_count}] </div>
          {Session.UserId == 'dexterity' ? <td width='45px'><button onClick={() => DataDelete(Data)}>삭제</button></td> : ''}
        </div>
      ))}
    </div>
  );
}

export default NewsBoard;