import {useContext} from 'react';
import {Context,withContext} from './ServiceContext';

function Service(){
    let {state,dispatch} = useContext(Context);
    const ClickAction=()=>{
        dispatch({type:'SET_LOT_GRID_ROWS',value : "HI"});
    }
    return <div>
        <button onClick={()=>ClickAction()}></button>
        <label>{state.LOT_GRID_ROWS}</label>
    </div>
}
export default withContext(Service);