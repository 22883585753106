import React, { useState, useEffect, useContext } from 'react';
import { HomeViewContext, withContext } from './HomeViewContext';
import M_footer from '../../image/design/M_footer.png';
import mobileHome from '../../image/design/mobileHome.png';
import ThumbnailViewer from '../../Common/Component/ThumbnailViewer/mobile';
import SlideShowGallery from '../../Common/Component/SlideShowGallery/index';
import * as property from './HomeViewProperty.js';
import * as actions from './HomeViewActions.js';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import NewsViewer from './parts/NewsViewer/m_index';
import '../M.style.css';

function HOME() {
  const { context, dispatch } = useContext(HomeViewContext); //모듈 전역 컨텍스트
  const [state, setState] = useState(property.InitializeState); //모듈 지역 스테이트 //
  const view = { state, setState, context, dispatch };
  useEffect(() => { actions.InitializeScreen(view); }, []);


  return (
    <div id='WrapperBox' className='Wrapper'>
      <div id='MobilelTabPage' style={property.TabPageStyle}>
        <ul id='MobileTabNavigater' style={property.SubNavStyle} >
          <li style={property.ListStyle}><Link to="/" className="active"> 홈</Link></li>
          <li style={property.ListStyle}><Link to="/TimeLine" className=""> 뉴스피드</Link></li>
          <li style={property.ListStyle}><Link to="/Photo" className="active"> 타임라인</Link></li>
        </ul>
      </div>
      <div className='HomeMasterPanel'>
        <SlideShowGallery/>
        {/* <img width='100%' src={mobileHome} className='BigImg'></img> */}
        <ThumbnailViewer />
        <div style={{ margin: '30px 0px 0px 0px', width: '100%', padding: '10px', borderRadius: '15px'}}>
          <div>
            <div id='newsPanel' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <NewsViewer NewsData={state.공지사항_게시글} title={'공지사항 게시판'} />
              <NewsViewer NewsData={state.자유게시판_게시글} title={'자유 게시판'} />
            </div>
          </div>
        </div>
        <img src={M_footer} style={{ width: '100%' }}></img>
      </div>
    </div>
  );
}

export default withContext(HOME);
