import React from 'react';
import ChatRoom from '../../Common/Component/ChatRoom/ChatRoom';
import Board from '../../Common/Component/Board/Mobile';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import chatroom from '../../image/design/CommunityMenu_chatroom.jpg';
import Freeboard from '../../image/design/CommunityMenu_freeboard.jpg';
import Camera from '../../image/design/CommunityMenu_photo.jpg';
import Camcorder from '../../image/design/CommunityMenu_video.jpg';
import RemoteSupport from '../../image/design/CommunityMenu_remote.jpg';
import Cloud from '../../image/design/CommunityMenu_cloud.jpg';
import Kakao from '../../image/design/CommunityMenu_kakao.jpg';
import Blog from '../../image/design/CommunityMenu_blog.jpg';
import '../style.css';

function NOTICE() {

  return (
    <div className='M_Wrapper'>
      <h1>앤츠네스트 작업실</h1>
      {/* <div className='M_NoticeMasterPanel'>
        <div className='M_CommunityBox'><Link to='/Join'><img src={chatroom} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><Link to='/Board'><img src={Freeboard} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><Link to='/Photo'><img src={Camera} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><a href="http://story.kakao.com/ch/ants"><img src={Kakao} width='100%' height='100%' /></a></div>
        <div className='M_CommunityBox'><Link to='/Video'><img src={Camcorder} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><Link to='/Remote'><img src={RemoteSupport} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><Link to='/Cloud'><img src={Cloud} width='100%' height='100%' /></Link></div>
        <div className='M_CommunityBox'><a href="https://blog.naver.com/yhs1103korea"><img src={Blog} width='100%' height='100%' /></a></div>
      </div> */}
    </div>
  );
}

export default NOTICE;
