import React from 'react';
import * as service from './HelpService'
import ReactDOM from 'react-dom';
import LinkBugBoard from './Bug';
import LinkInquiryBoard from './Inquiry';
import LinkSuggestionsBoard from './Suggestions';
import { CategoryRefrash } from './HelpService';


export const ActiveCheck = (Menu, MenuArray, props, setSelectedCategory, setCategory) => {
    try {
        MenuArray.map((Item, Index) => {
            if (Item.current.className === 'TabActive')
                Item.current.className = '';
        })
        if (Menu.current.className === 'TabActive')
            Menu.current.className = '';
        else {
            Menu.current.className = 'TabActive';
            if (Menu.current.id === 'Bug') {
                service.getBugCategory(setCategory);
                setSelectedCategory('ALL');
                ReactDOM.render(<LinkBugBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
            }
            else if (Menu.current.id === 'Inquiry') {
                service.getInquiryCategory(setCategory);
                setSelectedCategory('ALL');
                ReactDOM.render(<LinkInquiryBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
            }
            else if (Menu.current.id === 'Suggestions') {
                service.getSuggestionsCategory(setCategory);
                setSelectedCategory('ALL');
                ReactDOM.render(<LinkSuggestionsBoard args={props.args} CategorySelect={CategoryRefrash} LinkAction={true} />, document.getElementById('Board_View'));
            }
        }
    }
    catch (err) {
        alert(err);
    }
}