import React, { createRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Loginboxtitle from '../../../image/design/Loginboxtitle.jpg';
import banner from '../../../image/design/banner.png';
import memberInfo from '../../../image/design/memberInfo.jpg';



function LoginBox(props) {
  const [profile, setProfile] = useState({ "fileName": "" });
  const [Session, setSession] = useState({UserId:'',Name:''});
  useEffect(() => {
    CreateSession();
    ReadProfile();
  }, []);
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setSession(msg) })
      .catch(err => alert(err + '오류'));
  }
  
  const LoginOut = () => {
    fetch('/request/Logout', { credentials: 'include' })
      .then(() => props.getSession())
      .catch(err => alert(err + '로그아웃 오류'));
  }

  const changeProfile = () => {
    let fileinfo = document.getElementById("img").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      uploadImage();
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }

  // 프로필 사진 가져오기
  const ReadProfile = () => {
    fetch('/request/ReadProfile', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "UserId": Session.UserId })
    })
      .then(res => res.json())
      .then(msg => sendImage(msg))
      .catch(err => alert(err));
  }


  // 프로필 변경
  const uploadImage = () => {
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    formData.append('userId',Session.UserId);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/ProfileChange', {
      method: 'POST',
      mode: 'cors',
      credentials:'include',
      body: formData
    }).then((res) => console.log(res))
      .then(() => ReadProfile())
      // .catch(err => alert(err));
  }

  // 프로필 파일명 세팅
  const sendImage = (data) => {
    //alert(JSON.stringify(data));
    if (data.length === 0) {
      setProfile({ ...profile, fileName: 'NoImage.png' });
    }
    else {
      setProfile({ ...profile, fileName: data[0].file_name });
    }
  }


  return (
    <div className='CenterBox'>
      <div className='Theme'>
        <img style={{ marginRight: '30px', borderRadius: "10px", boxShadow: '2px 2px 2px 2px #f2f2f2' }} src={banner} />
      </div>
      <table className='LoginTable'>
        <tr>
          <div className='UserInfo'>
            <div style={{ display: 'flex', height: '40px', justifyContent: 'space-between' }}>
              <img src={Loginboxtitle} width='103px' height='19px'></img>
              <Link to='/MyInfoUpdate'><img src={memberInfo} style={{ margin: '-3px' }} height='26px'></img></Link>
            </div>
            <hr style={{ marginBottom: '45px' }} color='#eaeaea' />
            <div style={{ marginBottom: '45px' }} class='개인정보'>
              <div class='프로필사진' Link>
                <form id='myForm' method="POST" encType="multipart/form-data" >
                  <div className='사진파일_배경'>
                    <label for='img'><img className='프로필_이미지' src={process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + profile.fileName} /></label>
                    <input className='사진파일_히든' id='img' type="file" name="img" accept="image/*" onChange={() => changeProfile()} />
                  </div>
                </form>
              </div>
              <div class='계정정보'>
                <div><b>아이디</b> : {Session.UserId}</div>
                <div><b>사용자명</b> : {Session.Name}</div>
                <div><b>등급</b> : 회원</div>
              </div>
            </div>
            <Link to='MyPage'><button style={{ marginBottom: '15px', width: '325px', height: '50px', borderColor: '#eaeaea', borderStyle: 'solid', backgroundColor: '#00bed8', color: '#fff', borderRadius: '30px',cursor:'pointer' }}>마이페이지</button></Link>
            <button style={{ marginBottom: '15px', width: '325px', height: '50px', borderColor: '#eaeaea', borderStyle: 'solid', backgroundColor: '#fff', color: '#00bed8', borderRadius: '30px',cursor:'pointer' }} className='LoginButton' onClick={() => LoginOut()}>로그아웃</button>
          </div>
        </tr>
      </table>
    </div>
  );
}

export default LoginBox;