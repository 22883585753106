import React, { useState, useEffect } from 'react';

import './style.css';

let TodoItemList = [];
let date = new Date();

function ToDoList(Props) {

    let year = date.getFullYear();
    let month = date.getMonth();

    const [Todo, setTodo] = useState([]);
    const [toDoTitle, setToDoTitle] = useState("");
    const [Session, setSession] = useState({});
    const [clickDate, setClickDate] = useState(date.getDate());
    const [yymmdd, setYymmdd] = useState("");

    useEffect(() => {
        Parameters();
        searchToDoList(clickDate);
    }, []);

    let Parameters = () => {

        const viewYear = date.getFullYear();
        const viewMonth = date.getMonth();

        year = viewYear;
        month = viewMonth;

        const prevLast = new Date(viewYear, viewMonth, 0);
        const thisLast = new Date(viewYear, viewMonth + 1, 0);

        const PLDate = prevLast.getDate();
        const PLDay = prevLast.getDay();

        const TLDate = thisLast.getDate();
        const TLDay = thisLast.getDay();

        const prevDates = [];
        const thisDates = [...Array(TLDate + 1).keys()].slice(1);
        const nextDates = [];

        if (PLDay !== 6) {
            for (let i = 0; i < PLDay + 1; i++) {
                prevDates.unshift(PLDate - i);
            }
        }

        for (let i = 1; i < 7 - TLDay; i++) {
            nextDates.push(i);
        }

        const dates = prevDates.concat(thisDates, nextDates);
        const firstDateIndex = dates.indexOf(1);
        const lastDateIndex = dates.lastIndexOf(TLDate);

        dates.forEach((date, i) => {
            const condition = i >= firstDateIndex && i < lastDateIndex + 1 ? 'this' : 'other';
            dates[i] = `<div class='date'><span class=${condition} style="cursor:pointer;">${date}</span></div>`;
        });

        document.querySelector('.dates').innerHTML = dates.join('');

        const today = new Date();
        const tooday = viewMonth;
        const toooday = viewYear;

        if (viewMonth === today.getMonth() && viewYear === today.getFullYear()) {
            for (let date of document.querySelectorAll('.this')) {
                if (date.innerText == today.getDate()) {
                    date.classList.add('today');
                    break;
                }
            }
        }
    }

    let prevMonth = () => {
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        year = date.getFullYear();
        month = date.getMonth();
        setYymmdd(`${date.getFullYear()}년${date.getMonth() + 1}월${date.getDate()}일`);
        Parameters();

    }

    let nextMonth = () => {
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);

        year = date.getFullYear();
        month = date.getMonth();
        setYymmdd(`${date.getFullYear()}년${date.getMonth() + 1}월${date.getDate()}일`);
        Parameters();

    }

    let goToday = () => {
        date = new Date();
        setYymmdd(`${date.getFullYear()}년${date.getMonth() + 1}월${date.getDate()}일`);
        setClickDate(date);
        Parameters();
    }

  

    // 사용자 ToDoList 조회
    const searchToDoList = (innerDate) => {
        setClickDate(innerDate);

        let Data = { "ToDoDate": year.toString() + "-" + (month + 1).toString() + "-" + innerDate };
        fetch('/request/GetToDoList', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Data)
        })
            .then((res) => res.json())
            .then(msg => { TodoItemList = msg; return TodoItemList; })
            .then((Todo) => { setTodo(Todo); setYymmdd(`${year}년${month + 1}월${innerDate}일`); console.log(JSON.stringify(Todo)) })
            .catch(err => alert('ToDoList 가져오기 실패 ' + err));

        setToDoTitle("");
    }

    // 사용자 ToDoList 추가 
    const insertToDoList = (date) => {

        if (toDoTitle.trim() === "") {
            alert("내용이 없습니다.");
            return;
        }
        let Data = { "ToDoDate": year.toString() + "-" + (month + 1).toString() + "-" + date, "Contents": toDoTitle };

        fetch('/request/InsertToDoList', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Data)
        })
            .then((res) => { return res.json() })
            .then((res) => searchToDoList(clickDate))
            .catch(err => alert('ToDoList 추가 실패 ' + err));
    }

    // 사용자 ToDoList 수정 
    const updateToDoList = (num, check) => {
        let Data = { "Num": num, "Check": check };

        fetch('/request/UpdateToDoList', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Data)
        })
            .then((res) => { return res.json() })
            .then((res) => searchToDoList(clickDate))
            .catch(err => alert('ToDoList 수정 실패 ' + err));
    }

    // 사용자 ToDoList 삭제 
    const deleteToDoList = (num) => {
        let Data = { "Num": num };
        console.log('삭제수행:' + num);
        fetch('/request/DeleteToDoList', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Data)
        })
            .then((res) => { return res.json() })
            .then((res) => searchToDoList(clickDate))
            .catch(err => alert('ToDoList 삭제 실패 ' + err));
        setToDoTitle("");
    }

    return (
        <div style={{ margin: '10px' }}>
               <tr>
                <td width="40%">
                    <div className='header' style={{display:'flex',height:'120px',justifyContent:'center',alignItems:'center'}}>
                    </div>
                    <div className='todoList'>
                        <section style={{marginTop:'20px'}}>
                        <div style={{padding:'0px 30px' ,width:'100%'}}>
                        <input placeholder=' 할일을 입력하세요' style={{width:'80%',fontSize:'1.5rem' ,borderRadius:'5px', borderStyle:'ridge',borderColor:'silver'}} className="todoInput" type="text" name="toDoTitle" value={toDoTitle} onChange={({ target: { value } }) => setToDoTitle(value)} />
                        <button style={{width:'20%',fontSize:'1.5rem' ,borderRadius:'5px', borderStyle:'ridge',borderColor:'silver'}} onClick={() => insertToDoList(clickDate)}>추가</button>
                        </div>
                        {Todo.map((Data, i) => (
                            <div style={{ fontSize:'1.2rem',padding: '10px' ,borderRadius:'15px' ,margin:'10px 30px', borderStyle:'ridge',borderColor:'silver' }}>
                                <input type="checkbox" checked={Data.COMPLETE_CHECK === "Y" ? true : false} onChange={(e) => updateToDoList(Data.JOB_NUM, e.target.checked === true ? "Y" : "N")}></input>
                                <span>{Data.contents}</span>&nbsp;
                                <button onClick={() => deleteToDoList(Data.JOB_NUM)}>X</button>
                            </div>
                        ))}
                        </section>
                    </div>
                </td>
                <td width="60%">
                    <div className='CalendarClassic'>
                        <div className='header'>
                            {/* let Parameters를 사용함*/}
                            <div className='year-month' />
                            <label style={{ fontSize: '30px' }}>{yymmdd}</label>
                            <div className='nav'>
                                <button className='nav-btn go-prev' onClick={() => prevMonth()}>&lt;</button>
                                <button className='nav-btn go-today' onClick={() => goToday()}>Today</button>
                                <button className='nav-btn go-next' onClick={() => nextMonth()}>&gt;</button>
                            </div>
                        </div>
                        <div className='main'>
                            <div className='days'>
                                <div className='day'>일</div>
                                <div className='day'>월</div>
                                <div className='day'>화</div>
                                <div className='day'>수</div>
                                <div className='day'>목</div>
                                <div className='day'>금</div>
                                <div className='day'>토</div>
                            </div>
                            <div className='dates' onClick={(e) => searchToDoList(e.target.innerText)}></div>
                        </div>
                    </div>

                </td>
            </tr>
        </div>
    );
}
export default ToDoList;