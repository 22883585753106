import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import './style.css';

const CreateRoom = (props) => {
    const roomNameRef = useRef();
    const navigate = useHistory();

    async function create() {
        const roomName = roomNameRef.current.value;
        const response = await fetch('/request/rooms', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: roomName })
        });
        const room = await response.json();
        navigate.push(`/room/${room.id}`);
    }

    return (
        <div className="CreateRoomScreen">
        <div className="wrap">
            <label htmlFor="roomName">채팅방 이름: </label>
            <input type="text" placeholder="입력" id="roomName" ref={roomNameRef} />
            <button onClick={()=>create()}>방 생성하기</button>
        </div>
        </div>
    );
};

export default CreateRoom;