
export const SessionCheck = (view) => {
    fetch('/request/CreateSession', { credentials: 'include' })
        .then(res => res.json())
        .then((msg) => view.setState(prevState => ({ ...prevState, Session: msg })))
        .catch(err => alert(err + '오류'));
}
export const getNewsBoardData = (view) => {
    fetch('/request/SelectNews')
        .then(res => res.json())
        .then(msg => view.setState(prevState => ({ ...prevState, 공지사항_게시글: msg })))
        .catch(err => alert('오류'));
}
export const getQAboardData = (view) => {
    fetch('/request/SelectQA')
        .then(res => res.json())
        .then(msg => view.setState(prevState => ({ ...prevState, QA_게시글: msg })))
        .catch(err => alert('오류'));
}

export const getFreeboardData = (view) => {
    fetch('/request/SelectFree')
        .then(res => res.json())
        .then(msg => view.setState(prevState => ({ ...prevState, 자유게시판_게시글: msg })))
        .catch(err => alert('오류'));
}