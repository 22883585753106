import React, { createRef, useState, useEffect } from 'react';
import DefaultImg from '../../../../ftp/NoImage.png';
import MiniLogin from '../../../../Common/Component/LoginBox/MiniLogin';


function Infra(props) {
  useEffect(() => {
  }, []);
  const PhotoSubmit = createRef();
  const [SendData, setSendData] = useState({ "num": "", "name": "", "write": "", "contents": "" });
  const [DefaultImage, setDefaultImage] = DefaultImg;
  const [Feed, setFeed] = useState([]);
  const [Session, setSession] = useState({});
  const [profile, setProfile] = useState({ "fileName": "" });
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => {
        if (msg.State === 'LOGIN') GetIfaPhoto_ORG();
        setSession(msg)
      })
      .catch(err => alert(err + '오류'));
  }
  const DeletePortpolio=(key)=>{
    console.log('삭제하려는 항목 번호:'+key)
    fetch('/request/DeletePortpolio_infra', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({Key:key})
    })
      .then(() => {
        alert(key+'삭제완료');
        //Research();
    })
      .catch(err => alert('DeletePortpolio_infra' + err));
  }
  const uploadImgPreview = () => {
    let fileinfo = document.getElementById("img").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      document.getElementById("thumbnailImg").src = reader.result;
      document.getElementById("thumbnailImg").width = '300';
      document.getElementById("thumbnailImg").height = '300';
      //document.getElementById("thumbnailSrc").innerText=reader.result;
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }
  const clearData = () => {
    PhotoSubmit.current.disabled = false;
    document.getElementById("img").value = null;
    document.getElementById("TextInput").value = null;
    document.getElementById("thumbnailImg").src = null;
    document.getElementById("thumbnailImg").width = '0';
    document.getElementById("thumbnailImg").height = '0';
    GetIfaPhoto_ORG();
  }
  const GetIfaPhoto_ORG = () => {
    fetch('/request/GetIfaPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setFeed(msg) })
      //    .then(msg=>alert(JSON.stringify(msg)))
      .catch(err => alert(err + '오류'));
  }
  const uploadImage = () => {
    PhotoSubmit.current.disabled = 'disabled';
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    formData.append('userId',Session.UserId);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/InfraFileUpload', {
      method: 'POST',
      mode: 'cors',
      credentials:'include',
      body: formData
    }).then((res) => {
      clearData();
    })
      .catch(err => 
        console.log(err));
  }
  useEffect(() => {
    CreateSession();

  }, []);
  return (
    <div>
    <div style={{ minHeight: '600px' }} className='PortfolioMasterPanel'>
      {Session.State != 'LOGIN' ? <div><h2>로그인 사용자만 이용가능합니다.</h2><MiniLogin  getSession={CreateSession}/></div> :<>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className='WriteModeBox'>

          <section style={{ display: 'flex', width: '100%' }}>
            <span style={{ width: '300px', height: '290px', borderStyle: 'solid', borderWidth: '1px' }}>
              <img width="300px" height="290px" id="thumbnailImg" src={process.env.REACT_APP_API_ENDPOINT+'/antsnest/ProfileImage/' + 'NoImage.png'} />
              <div id="thumbnailSrc" src="" /></span>
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%', paddingLeft: '10px' }}>
              <form id='myForm' method="POST" encType="multipart/form-data" >
                <div>
                  <input placeholder="제목" style={{ width: '100%', height: '40px' }}></input>
                  <textarea placeholder='설명을 입력하세요' style={{ width: '100%', height: '155px', marginTop:'10px',resize:'none' }} id='TextInput' name="TextInput" value={SendData.contents} onChange={(ev) => setSendData({ ...SendData, contents: ev.target.value })}></textarea>
                </div>
                <hr />
                <div style={{display:'inline-flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                <input id='img' type="file" name="img" onChange={() => uploadImgPreview()} /><input placeholder="카테고리" style={{ width: '20%', height: '20px' }}></input>
                </div>
                <hr />
              </form>
              <button ref={PhotoSubmit} onClick={() => uploadImage()}>게시</button>
            </div>
          </section>
         
        </div>
         <img width="180" height="180"/>
         <img width="180" height="180"/>
         <img width="180" height="180"/>
         <img width="180" height="180"/>
         <img width="180" height="180"/>
         <img width="180" height="180"/>
         <hr width="100%"></hr>
         </>
        }
       
        {Feed.length === 0 ? '' : Feed.map((Data, i) => (
    <section style={{ width:'100%', background:'#fff' , borderRadius:'10px',margin:'10px' ,alignItems:'center'}}>
    <article style={{display:'flex',flexDirection:'row'}}>
    <img style={{padding:'10px' ,margin:'10px'}}  width="180" height="180" src={Data.pix_loadPath != undefined ? process.env.REACT_APP_API_ENDPOINT+'/antsnest/InfraFile/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
    <main style={{width:'70%'}}>
    <div style={{padding:'10px' ,margin:'10px'}}>프로젝트명 : </div>
    <div style={{padding:'10px' ,margin:'10px'}}>담당업무 : </div>
    <div style={{padding:'10px' ,margin:'10px'}}>작업기간 : </div>
    </main>
    <aside>
      <figure><button>편집</button></figure>
      <figure><button onClick={()=>DeletePortpolio(Data.pix_num)}>삭제</button></figure>
    </aside>
    </article>
</section>
))}
</div>
</div>
  );
}



export default Infra;
