import {useState} from 'react';
export const InitializeState = {
    공지사항_게시글: [{ "컬럼1": "", "컬럼2": "" }],
    QA_게시글: [{ "컬럼1": "", "컬럼2": "" }],
    자유게시판_게시글: [{ "컬럼1": "", "컬럼2": "" }],
    Session: {},
}

export let TabPageStyle = {
    display: 'flex',
    flexWrap: "wrap",
};
export let SubNavStyle = {
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '0',
    flexWrap: "wrap",
    backgroundColor: '#fff',
};
export let ListStyle = {
    display: 'flex',
    listStyleType: 'none',
    margin: '15px',
};

const initializeState ={
    state1 :'state',
    state2 :'state',
}

export function useCombineState(initializeState){
    const [state1,setState1] =useState(initializeState.state1);
    const [state2,setState2] =useState(initializeState.state2);

    const setters = {
        setState1,
        setState2,
    }
    const getters = {
        state1,
        state2,
    }

    const get = (e)=>{
        return getters[e];
    }
    const set = (setName,v)=>{
        setters[setName](v);
    }
    return {get,set};
}