import React from 'react';



export default function HrCard(props) {
    return (
    <div className="Bizcard ">
        <img width="300px" height="300px" src={props.Photo} alt="Dexterity" />
        <div style={{textAlign:'center'}} className="containerBox">
          <h2>{props.Name}</h2>
          <p className="title">{props.Work}</p>
          <p>[{props.Title}]<br/>
            {props.Work2}
          </p>
          <p>{props.Email}</p>
          <p><button className="Bizbutton">Contact</button></p>
        </div>
      </div>
    );
}

