import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import './style.css';

function MyPage(props) {
    useEffect(() => {

    }, []);

    const [LoginData, setLoginData] = useState({"UserId":"","UserPw":"","UserCheckPw":"","UserEmail":"","Gender":"1"});
    const [radio, setRadio] = useState('1');
    const [Session, setSession] = useState({});
    const onChangeHandler = (e) => {
        LoginData.Gender = e.target.value;
        setRadio(e.target.value);
    }

    useEffect(() => {

    }, []);

    return (
        <div id='JoinPopupView' className='my-page-container'>
            <span id='UserInfoLayout'>
                <form>
                    <h1>마이 페이지</h1>
                    <hr />
                    <div className="clearfix">
                        <button type="button" className="signupbtn">회원정보수정</button>
                        <button type="button" className="signupbtn">내 활동내역</button>
                        <button type="button" className="signupbtn">댓글 알람</button>
                        <button type="button" className="signupbtn">내 수강일지</button>
                        <button type="button" className="signupbtn">보유 포인트</button>
                        <button type="button" className="signupbtn">1:1 문의</button>
                    </div>
                    <br />
                </form>
                <br /><br /><br /><br />
            </span>
        </div>
    );
}

export default MyPage;
