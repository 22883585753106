
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import GanttChartView from './GanttChartView'; // GanttChartView 컴포넌트 임포트
import ResizableTableColumn from './Test';

export default function GanttChart({Session}){

  return (
    <div>
        <GanttChartView Session={Session} />
        {/* <ResizableTableColumn></ResizableTableColumn> */}
    </div>
  );
};

