export const getBugCategory = (setCategory) => {
    fetch('/request/BugCategoryList', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    }
    ).then(res => res.json())
        .then((msg) => {
            setCategory(msg);
        })
        .catch(err => alert('BugCategoryList' + err));
}
export const getInquiryCategory = (setCategory) => {
    fetch('/request/InqueryCategoryList', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    }
    ).then(res => res.json())
        .then((msg) => {
            setCategory(msg);
        })
        .catch(err => alert('오류 InquiryCategoryList' + err));
}

export const getSuggestionsCategory = (setCategory) => {
    fetch('/request/SuggestionsCategoryList', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    }
    ).then(res => res.json())
        .then((msg) => {
            setCategory(msg);
        })
        .catch(err => alert('SuggestionsCategoryList' + err));
}


export const CategoryRefrash = (handleSaveClick) => {
    CategorySearch = handleSaveClick;
}

export const SelectedCategory = (e, setSelectedCategory) => {
    setSelectedCategory(e.target.value);
    if (CategorySearch !== undefined)
        CategorySearch(e.target.value);
}

var CategorySearch = undefined;