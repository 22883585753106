import React from 'react';

import './Message.css';

import ReactEmoji from 'react-emoji';

const Message = ({ message: { text, user }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = (name===undefined)?'Anonymous':name.trim().toLowerCase();

  if(user === trimmedName) {
    isSentByCurrentUser = true;
  }

  return (
    isSentByCurrentUser
      ? (
        <div style={{flexDirection:'column'}} className="messageContainer justifyEnd">
            <div style={{fontSize:'0.6rem' ,justifyContent:'flex-end'}} className="sentText">{trimmedName}</div>
            <section style={{display:'flex' ,justifyContent:'flex-end'}}>
            <div style={{width:'fit-content',textAlign:'left',borderTopRightRadius:'0px'}} className="messageBox backgroundBlue">
              <div className="messageText colorWhite">{ReactEmoji.emojify(text)}</div>
            </div>
          </section>
        </div>
        )
        : (
          <div style={{flexDirection:'column'}} className="messageContainer justifyStart">
              <div  style={{fontSize:'0.6rem',justifyContent:'flex-start'}} className="sentText">{user}</div>
              <div style={{width:'fit-content',textAlign:'left',borderTopLeftRadius:'0px'}} className="messageBox backgroundLight">
              <section style={{display:'flex' ,justifyContent:'flex-start'}}>
                <div className="messageText colorDark">{ReactEmoji.emojify(text)}</div>
              </section>
              </div>
            
          </div>
        )
  );
}

export default Message;