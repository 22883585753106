import { useEffect ,useState} from 'react';
import MbannerImg1 from '../../../image/design/Mbanner1.png'
import MbannerImg2 from '../../../image/design/Mbanner2.png'
import MbannerImg3 from '../../../image/design/Mbanner3.png'

import DefaultImg4 from '../../../image/design/banner.png'
import DefaultImg5 from '../../../image/design/banner_2.png'

import './style.css';


function SlideShowGallery() {

    const [slideIndex, setSlideIndex] = useState(3);
      
      useEffect(() => { // 폼로드와 비슷한 개념
        
        const interval = setInterval(() => {
          if (slideIndex === 1 || slideIndex === 2) {
            plusSlidesAuto(1);
        } else{
          plusSlidesAuto(-2);
        }
        }, 5000);
    
        return () => clearInterval(interval);
        // slideIndex=1;
        // showSlides(slideIndex);
      }, [slideIndex]); 

    let plusSlidesAuto = (n) => {
      let newIndex = slideIndex + n;
      if (newIndex > 3) {
        console.log(newIndex);
        newIndex = 1;
      } else if (newIndex < 1) {
        console.log(newIndex);
        newIndex = 3;
      }
      showSlides(newIndex);
    };
 
      


    const showSlides=(n)=> {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    // var captionText = document.getElementById("caption");

    if (n > slides.length) {n = 1}

    if (n < 1) {n = slides.length;}
    
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace("GalleryActive", "");
    }
    slides[n-1].style.display = "block";
    dots[n-1].className += "GalleryActive";
    setSlideIndex(n);
    // captionText.innerHTML = dots[slideIndex-1].alt;
    }
    

    // Next/previous controls
    // let plusSlides=(n)=> {
    //     showSlides(slideIndex += n);
    // }
    let plusSlides = (n) => {
      // setSlideIndex((prevIndex) => {
      //   let newIndex = prevIndex + n;
      //   if (newIndex > 3) {
      //     newIndex = 1;
      //   } else if (newIndex < 1) {
      //     newIndex = 3;
      //   }
      //   showSlides(newIndex);
      //   return newIndex;
      // });
      let newIndex = slideIndex + n;
        if (newIndex > 3) {
          newIndex = 1;
        } else if (newIndex < 1) {
          newIndex = 3;
        }
        showSlides(newIndex);
    };
    // let currentSlide=(n)=> {
    //     showSlides(slideIndex = n);
    // }
    let currentSlide = (n) => {
      showSlides(n);
    };
    
  return (
  <div className="GalleryContainer">

    <div className="mySlides">
      <div className="numbertext">{slideIndex} / 3</div>
        <img src={DefaultImg4} className='defaultImg'/>
        <img src={MbannerImg1} className='MbannerImg'/>
    </div>

    <div className="mySlides" style={{display: 'none'}}>
      <div className="numbertext">{slideIndex} / 3</div>
        <img src={DefaultImg5} className='defaultImg'/>
        <img src={MbannerImg2} className='MbannerImg'/>
    </div>

    <div className="mySlides" style={{display: 'none'}}>
      <div className="numbertext">{slideIndex} / 3</div>
        <img src={DefaultImg4} className='defaultImg'/>
        <img src={MbannerImg3} className='MbannerImg'/>
        
    </div>

    <a className="prev" onClick={()=>plusSlides(-1)}>&#10094;</a>
    <a className="next" onClick={()=>plusSlides(1)}>&#10095;</a>

    {/* <div className="caption-container">
      <span id="caption"></span>
    </div> */}

    <div className="row">
      <div className="column">
        <div className="demo cursor"  onClick={()=>currentSlide(1)} alt="사업기획"></div>
      </div>
      <div className="column">
        <div className="demo cursor"  onClick={()=>currentSlide(2)} alt="개요"></div>
      </div>
      <div className="column">
        <div className="demo cursor"  onClick={()=>currentSlide(3)} alt="AI 홀로그램 결합"></div>
      </div>
    </div>
  </div>
    );
}
  
  export default SlideShowGallery;
