import React from "react";


export default function MhrCard(props) {

    return (
        <div className="Bizcard">
            <img width="110px" height="110px" src={props.Photo} alt="Dexterity" />
            <div className="containerBox">
            <h3>{props.Name}</h3>
            <p className="title">{props.Work}</p>
            <p>[{props.Title}]<br />
                {props.Work2}</p>
            <p>{props.Email}</p>
            <p><button className="Bizbutton">Contact</button></p>
            </div>
        </div>


    )

}

