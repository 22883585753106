import { useEffect, useState } from "react";

const Countdown = ({seconds})=>{
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(()=>{
        if(!timeLeft) return;

        const intervalid = setInterval(()=>{
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return ()=> clearInterval(intervalid);
    },[timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const secs = timeLeft % 60;

    return(
        <div>
            {minutes}:{secs<10 ? '0${secs}':secs}
        </div>
    );
};

export default Countdown;