import React, { createRef, useState, useEffect } from 'react';
import DefaultImg from '../../../../ftp/NoImage.png';


function Designer(props) {
  useEffect(() => {
  }, []);
  const PhotoSubmit = createRef();
  const [SendData, setSendData] = useState({ "num": "", "name": "", "write": "", "contents": "" });
  const [DefaultImage, setDefaultImage] = DefaultImg;
  const [Feed, setFeed] = useState([]);
  const [Session, setSession] = useState({});
  let SessionValue = {};
  const CreateSession = () => {
    fetch('/request/CreateSession', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => {
        if (msg.State === 'LOGIN') GetFeedPhoto_ORG();
        setSession(msg)
      })
      .catch(err => alert(err + '오류'));
  }

  const uploadImgPreview = () => {
    let fileinfo = document.getElementById("img").files[0];
    let reader = new FileReader();
    reader.onload = function () {
      document.getElementById("thumbnailImg").src = reader.result;
      document.getElementById("thumbnailImg").width = '300';
      document.getElementById("thumbnailImg").height = '300';
      //document.getElementById("thumbnailSrc").innerText=reader.result;
    }
    if (fileinfo) {
      reader.readAsDataURL(fileinfo);
    }
  }
  const clearData = () => {
    PhotoSubmit.current.disabled = false;
    document.getElementById("img").value = null;
    document.getElementById("TextInput").value = null;
    document.getElementById("thumbnailImg").src = null;
    document.getElementById("thumbnailImg").width = '0';
    document.getElementById("thumbnailImg").height = '0';
    GetFeedPhoto_ORG();
  }
  const GetFeedPhoto_ORG = () => {
    fetch('/request/GetDgnPhoto_ORG', { credentials: 'include' })
      .then(res => res.json())
      .then((msg) => { setFeed(msg) })
      //    .then(msg=>alert(JSON.stringify(msg)))
      .catch(err => alert(err + '오류'));
  }
  const uploadImage = () => {
    PhotoSubmit.current.disabled = 'disabled';
    var form = document.getElementById("myForm");
    var formData = new FormData(form);
    fetch(process.env.REACT_APP_UPLOAD_ENDPOINT+'/upload/DesignerFileUpload', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    }).then((res) => res.json()).then((msg) => { clearData(); })
      .catch(err => alert(err));
  }
  useEffect(() => {
    CreateSession();

  }, []);
  return (
    <div>
      <div style={{ minHeight: '600px' }} className='M_PortfolioMasterPanel'>
        {Session.UserId == undefined ? <div>로그인이 필요한 서비스 입니다.</div>
          :
          <span>

            {Feed.length === 0 ? '' : Feed.map((Data, i) => (
              <img width="140" height="140" src={Data.pix_loadPath != undefined ? '/server/DesignerFile/' + Data.pix_loadPath : process.env.REACT_APP_API_ENDPOINT+'/antsnest/App/' + 'NoImage.png'} />
            ))}
          </span>
        }
      </div>
    </div>
  );
}



export default Designer;
